var cdn_good = true;

var clockTimeoutID;
var monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
var dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
var paging_setup = [];
var last_search_term = '';
var search_limit = 15;
var search_order = 'ship_name';
var last_quick_search = '';
var last_quick = '';
var quick_timeout;
var feedback_timeout;
var quick_search_timeout = null;
var stop_quick_timeout = false;
var stop_feedback_timeout = false;
var quick_go = false;
var feedback_showing = false;
var feedback_default = '';
var activetab = {};
var activesubtab = {};
var defaulttab = {};
var nocache = {};
var quickfilters = [
  'vessels',
  'ex',
  'dead',
  'operators',
  'owners',
  'builders',
  'portfolios',
  'ports',
  'gis',
];
var tradewinds_news;
var tradewinds_pos = -1;
var tradewinds_timer;
var hasDisplayElements = [];

var dots_interval = false;
var dots_tick = 0;
var dots_count = 4;
var dots_ticks = 10;
var dots_step = 100;

var ctrlDown = false;

var quicktabbed = {
  quickvessels: false,
  quickex: false,
  quickdead: false,
  quickbuilders: false,
  quickoperators: false,
  quickowners: false,
  quickportfolios: false,
  quickports: false,
  quickgis: false,
};

var limiter = {
  quickvessels: false,
  quickex: false,
  quickdead: false,
  quickbuilders: false,
  quickoperators: false,
  quickowners: false,
  quickportfolios: false,
  quickports: false,
  quickgis: false,
};

var limit = {
  quickvessels: 10,
  quickex: 10,
  quickdead: 10,
  quickbuilders: 10,
  quickoperators: 10,
  quickowners: 10,
  quickportfolios: 10,
  quickports: 10,
  quickgis: 10,
};

var total_limit = {
  quickvessels: search_total_limit,
  quickex: search_total_limit,
  quickdead: search_total_limit,
  quickbuilders: search_total_limit,
  quickoperators: search_total_limit,
  quickowners: search_total_limit,
  quickportfolios: search_total_limit,
  quickports: search_total_limit,
  quickgis: search_total_limit,
};

var sorting = {
  quickvessels: '',
  quickex: '',
  quickdead: '',
  quickbuilders: '',
  quickoperators: '',
  quickowners: '',
  quickportfolios: '',
  quickports: '',
  quickgis: '',
};

var sorting_original = {
  quickvessels: '',
  quickex: '',
  quickdead: '',
  quickbuilders: '',
  quickoperators: '',
  quickowners: '',
  quickportfolios: '',
  quickports: '',
  quickgis: '',
};

var before = {
  quickvessels: function () {},
  quickex: function () {},
  quickdead: function () {},
  quickbuilders: function () {},
  quickoperators: function () {},
  quickowners: function () {},
  quickportfolios: function () {},
  quickports: function () {},
  quickgis: function () {},
};

var action = {
  quickvessels: '',
  quickex: '',
  quickdead: '',
  quickbuilders: '',
  quickoperators: '',
  quickowners: '',
  quickportfolios: '',
  quickports: '',
  quickgis: '',
};

var after = {
  quickvessels: function (json) {
    return quickAfterCall('vessels', json);
  },
  quickex: function (json) {
    return quickAfterCall('ex', json);
  },
  quickdead: function (json) {
    return quickAfterCall('dead', json);
  },
  quickbuilders: function (json) {
    return quickAfterCall('builders', json);
  },
  quickoperators: function (json) {
    return quickAfterCall('operators', json);
  },
  quickowners: function (json) {
    return quickAfterCall('owners', json);
  },
  quickportfolios: function (json) {
    return quickAfterCall('portfolios', json);
  },
  quickports: function (json) {
    return quickAfterCall('ports', json);
  },
  quickgis: function (json) {
    return quickAfterCall('gis', json);
  },
};

var table_scroll_pos = {};
var show_count = {};

Math.log = (function () {
  var log = Math.log;
  return function (n, base) {
    if (typeof base == 'undefined' || base == Math.E) return log(n);
    else if (base == 10) return log(n) / Math.LN10;
    else if (base == 2) return log(n) / Math.LN2;
    else return log(n) / log(base);
  };
})();

if (!Math.ln)
  Math.ln = function (n) {
    return Math.log(n);
  };

if (!Math.log10)
  Math.log10 = function (n) {
    return Math.log(n, 10);
  };

if (!Math.log2)
  Math.log2 = function (n) {
    return Math.log(n, 2);
  };

String.prototype.ucwords = function () {
  return this.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function ($1) {
    return $1.toUpperCase();
  });
};

function hasDisplayShow(id) {
  if (typeof hasDisplayElements[id] != 'undefined') $(hasDisplayElements[id]).show();
}

function hasDisplayFix(el) {
  if (typeof el === 'undefined') return;
  if (!$.browser.msie || parseInt($.browser.version, 10) >= 9) return;
  var id = Math.random();
  hasDisplayElements[id] = $(el);
  $(el).hide();
  setTimeout('hasDisplayShow(' + id + ')', 10);
}

function number_format(number, decimals, dec_point, thousands_sep) {
  // discuss at: http://phpjs.org/functions/number_format
  // *     example: number_format(1234.56, 4, '.', ',');
  // *     returns: '1,234.5600'

  var n = number,
    prec = decimals;
  var toFixedFix = function (n, prec) {
    var k = Math.pow(10, prec);
    return (Math.round(n * k) / k).toString();
  };
  n = !isFinite(+n) ? 0 : +n;
  prec = !isFinite(+prec) ? 0 : Math.abs(prec);
  var sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep;
  var dec = typeof dec_point === 'undefined' ? '.' : dec_point;
  var s = prec > 0 ? toFixedFix(n, prec) : toFixedFix(Math.round(n), prec); //fix for IE parseFloat(0.55).toFixed(0) = 0;
  var abs = toFixedFix(Math.abs(n), prec);
  var _, i;
  if (abs >= 1000) {
    _ = abs.split(/\D/);
    i = _[0].length % 3 || 3;
    _[0] = s.slice(0, i + (n < 0)) + _[0].slice(i).replace(/(\d{3})/g, sep + '$1');
    s = _.join(dec);
  } else {
    s = s.replace('.', dec);
  }
  var decPos = s.indexOf(dec);
  if (prec >= 1 && decPos !== -1 && s.length - decPos - 1 < prec) {
    s += new Array(prec - (s.length - decPos - 1)).join(0) + '0';
  } else if (prec >= 1 && decPos === -1) {
    s += dec + new Array(prec).join(0) + '0';
  }
  return s;
}

/**
 * @param el_pos {string} - Defines which position on the element being positioned to align with the target element: "horizontal vertical" alignment.
 * @param target_pos {string} - Defines which position on the target element to align the positioned element against: "horizontal vertical" alignment.
 * @param target {Selector | Element | jQuery | Event} - Which element to position against. If you provide a selector or jQuery object, the first matching element will be used.
 */
function dialog_resize(el_pos, target_pos, target) {
  return $(window).on('resize', function () {
    var dialogs = $('.ui-dialog:visible');
    dialogs.each(function (a, e) {
      $(e).position({ my: el_pos, at: target_pos, of: target });
    });
  });
}

function expiryNotice() {
  $('#expiry_notice').dialog({
    width: 500,
    modal: true,
    resizable: false,
    draggable: false,
    buttons: [
      {
        text: 'Remind me in 10 days',
        id: 'expiry_notice_reminder_btn',
        click: function () {
          bb_get(site_url + 'ajax/expiry_reminder/', function () {});
          $('#expiry_notice').dialog('close');
        },
      },
      {
        text: 'Submit',
        id: 'expiry_notice_submit_btn',
        click: function () {
          bb_post(
            site_url + 'ajax/expiry_enquiry/',
            {
              enquiry: $('#expiry_enquiry').val(),
            },
            function (json) {
              switch (json.notification) {
                case 'error':
                  $('#error_notification')
                    .animate({ height: '30px', color: 'red' }, { duration: 1000 })
                    .html('No message provided, Please enter a message.');
                  break;
                case 'success':
                  $('#expiry_notice').html('<p>Thank you, your message has been sent.</p>');
                  $('#expiry_notice').dialog({
                    buttons: [
                      {
                        text: 'Close',
                        click: function () {
                          $('#expiry_notice').dialog('close');
                        },
                      },
                    ],
                  });
                  break;
              }
            },
          );
        },
      },
    ],
    open: function () {
      $('.ui-widget-overlay').css('position', 'fixed');
    },
    close: function () {
      bb_get(site_url + 'ajax/expiry_notice/', function () {});
      $(this).dialog('close');
      $('.ui-widget-overlay').css('position', 'absolute');
    },
  });
  dialog_resize('center', 'center', window);
}

function freeTrialNotice() {
  $('#free_trial_notice').dialog({
    width: 534,
    modal: true,
    resizable: false,
    draggable: false,
    buttons: [
      {
        text: "Don't show again",
        id: 'dont_show_btn',
        class: 'link',
        click: function (e) {
          e.preventDefault();
          bb_get(site_url + 'ajax/free_trial_stop/', function (json) {});
          $('#free_trial_notice').dialog('close');
        },
      },
      {
        text: 'Get trial',
        id: 'get_trial_btn',
        class: 'fr',
        click: function () {
          toggle_event = false;
          var popup = document.getElementById('free_trial_notice');
          popup.innerHTML =
            '<p>Thank you for registering your interest in a one month free trial. Your Account Manager will contact you to activate a product of your choice.</p><p>Would you like to continue?</p>';
          popup.style.background = '#FFFFFF';
          $('#free_trial_notice').dialog({
            title: 'One month free trial',
            buttons: [
              {
                text: 'Confirm',
                class: 'fr',
                click: function (e) {
                  e.preventDefault();
                  bb_post(site_url + 'ajax/free_trial_enquiry/', function (json) {
                    $('#free_trial_notice').dialog('close');
                  });
                },
              },
              {
                text: 'Cancel',
                class: 'inverted fr',
                click: function (e) {
                  e.preventDefault();
                  $('#free_trial_notice').dialog('close');
                },
              },
            ],
          });
        },
      },
      {
        text: 'Remind me later',
        id: 'remind_me_later_btn',
        class: 'inverted fr',
        click: function (e) {
          e.preventDefault();
          toggle_event = false;
          bb_get(site_url + 'ajax/free_trial_reminder/', function (json) {
            var popup = document.getElementById('free_trial_notice');
            popup.style.background = '#FFFFFF';

            popup.innerHTML = json.status;
            $('#free_trial_notice').dialog({
              title: 'One month free trial',
              buttons: [
                {
                  text: 'Close',
                  class: 'fr lg_input',
                  click: function () {
                    $('#free_trial_notice').dialog('close');
                  },
                },
              ],
            });
          });
        },
      },
    ],
    open: function (e) {
      $(e.target).focus();
      $(e.target).parent().find('.ui-dialog-buttonset').addClass('free_notice_buttons');
      $(e.target).css('background-color', '#cc0000');
      $('.ui-widget-overlay').css('position', 'fixed');
    },
    close: function (e) {
      e.preventDefault();
      if (
        e.key === 'Escape' ||
        (e.toElement !== undefined && e.toElement.classList.contains('ui-icon'))
      ) {
        bb_get(site_url + 'ajax/free_trial_reminder/', function (json) {
          $('#free_trial_notice').dialog('close');
        });
      }
    },
  });
  dialog_resize('center', 'center', window);
}

function productAnnouncement($product, $end_date, $width, $footer_html) {
  if ($width === undefined) {
    $width = 600;
  }
  var remind_me_later = true;
  $('#product_announcement').dialog({
    width: $width,
    modal: true,
    resizable: false,
    draggable: false,
    buttons: [
      {
        text: "Don't show again",
        id: 'dont_show_btn',
        class: 'link',
        click: function (e) {
          e.preventDefault();
          var popup = document.getElementById('product_announcement');
          popup.style.background = '#FFFFFF';
          popup.classList.add('vv-pb-0');
          popup.innerHTML =
            '<p class="ac"><img src="' +
            window.img_url +
            'loadingAnimation.gif" alt="Loading" /></p>';
          $('#product_announcement').dialog({
            title: $product + ' Demo',
            buttons: [],
          });
          bb_get(site_url + 'ajax/product_demo_stop/', { end_date: $end_date }, function (json) {
            remind_me_later = false;
            $('#product_announcement').dialog('close');
          });
        },
      },
      {
        text: 'Request a demo',
        id: 'request_a_demo_btn',
        class: 'fr',
        click: function (e) {
          e.preventDefault();
          var popup = document.getElementById('product_announcement');
          popup.style.background = '#FFFFFF';
          popup.classList.add('vv-pb-0');
          popup.innerHTML =
            '<p class="ac"><img src="' +
            window.img_url +
            'loadingAnimation.gif" alt="Loading" /></p>';
          $('#product_announcement').dialog({
            title: $product + ' Demo',
            buttons: [],
          });
          bb_post(
            site_url + 'ajax/product_demo/',
            { product: $product, end_date: $end_date },
            function (json) {
              remind_me_later = false;
              popup.innerHTML =
                '<p>Thank you for requesting a demo of ' +
                $product +
                '. Your Account Manager will contact you shortly</p>';
            },
          );
        },
      },
      {
        text: 'Remind me later',
        id: 'remind_me_later_btn',
        class: 'inverted fr',
        click: function (e) {
          e.preventDefault();
          var popup = document.getElementById('product_announcement');
          popup.style.background = '#FFFFFF';
          popup.classList.add('vv-pb-0');
          popup.innerHTML =
            '<p class="ac"><img src="' +
            window.img_url +
            'loadingAnimation.gif" alt="Loading" /></p>';
          $('#product_announcement').dialog({
            title: $product + ' Demo',
            buttons: [],
          });

          bb_get(site_url + 'ajax/product_demo_reminder/', {}, function (json) {
            remind_me_later = false;
            $('#product_announcement').dialog('close');
          });
        },
      },
    ],
    open: function (e) {
      $(e.target).focus();
      $(e.target).parent().find('.ui-dialog-buttonset').addClass('product_buttons');
      if ($footer_html !== undefined) {
        $(e.target)
          .parent()
          .find('.ui-dialog-buttonset')
          .append('<span class="product_footer">' + $footer_html + '</span>');
      }
      $(e.target).css('background-color', '#cc0000');
      $('.ui-widget-overlay').css('position', 'fixed');
    },
    close: function (e) {
      e.preventDefault();
      if (remind_me_later) {
        bb_get(site_url + 'ajax/product_demo_reminder/', {}, function (json) {
          $('#product_announcement').dialog('close');
        });
      }
    },
  });
  dialog_resize('center', 'center', window);
}

$(document).ready(function () {
  $('.upgrade_trigger').on('click', function () {
    var upg = Object.create($.extend(true, {}, new VV.Popup($(this).parent().attr('data-attr'))));

    upg.setPopupType('upgrade');
    upg.draw();
  });

  $('.coming_soon_trigger').on('click', function () {
    var upg = Object.create($.extend(true, {}, new VV.Popup($(this).parent().attr('data-attr'))));

    upg.setPopupType('coming_soon');
    upg.addProperties({ width: 555 });
    upg.draw();
  });
});

function tradewinds_tick() {
  tradewinds_pos++;
  if (
    tradewinds_pos >= lengthOf(tradewinds_news) ||
    typeof tradewinds_news[tradewinds_pos] == 'undefined'
  )
    tradewinds_pos = 0;
  var item = tradewinds_news[tradewinds_pos];
  if (typeof item == 'undefined') return;
  $('#news_ticker').fadeOut(250, function () {
    if (
      typeof item.link != 'undefined' &&
      item.link !== null &&
      typeof item.title != 'undefined' &&
      item.title !== null
    )
      $('#news_ticker')
        .html('<a href="' + item.link + '" class="popup" rel="nofollow">' + item.title + '</a>')
        .fadeIn(250);
  });
}

function bb_aftercall(response, status, xhr, aftercall) {
  switch (xhr.getResponseHeader('content-type')) {
    case 'application/xml':
    case 'text/xml':
      if (
        $(response).find('notification').length > 0 &&
        $(response).find('notification').text() == 'logout'
      )
        window.location = site_url + 'logout?ref=5';
      break;
    case 'application/json':
      if (
        typeof response == 'object' &&
        response != null &&
        typeof response.notification != 'undefined' &&
        response.notification == 'logout'
      )
        window.location = site_url + 'logout?ref=4';
      break;
  }
  return aftercall(response);
}

function bb_ajax(params, attempt) {
  if (typeof attempt == 'undefined') attempt = 0;
  if (attempt < 5) {
    if (typeof params['data'] == 'undefined') params['data'] = {};
    params['data']['ajax_attempts_made'] = attempt;
    params['error'] = function (xhr, status, response) {
      if (status != 'timeout') return false;
      return bb_ajax(params, attempt + 1);
    };
  }
  $.ajax(params);
}

function bb_ajax_run(url, method, a, b) {
  var aftercall = (typeof b === 'undefined' ? a : b) || function () {};
  var ajax_params = {
    url: url,
    method: method.indexOf('_') == -1 ? method : method.slice(0, method.indexOf('_')),
    success: function (response, status, xhr) {
      return bb_aftercall(response, status, xhr, aftercall);
    },
  };
  if (method.indexOf('_') != -1)
    ajax_params['dataType'] = method.slice(method.indexOf('_') + 1).toLowerCase();
  if (typeof b != 'undefined') ajax_params['data'] = a;

  bb_ajax(ajax_params, 0);
}

function bb_get(url, params, aftercall) {
  bb_ajax_run(url, 'GET', params, aftercall);
}

function bb_post(url, params, aftercall) {
  bb_ajax_run(url, 'POST', params, aftercall);
}

function bb_get_json(url, params, aftercall) {
  bb_ajax_run(url, 'GET_JSON', params, aftercall);
}

function str_replace(needle, replacement, haystack) {
  var rtn = haystack;
  if (!rtn && rtn !== 0) return '';
  if (typeof rtn != 'string') rtn = rtn.toString();
  while (rtn.indexOf(needle) != -1) {
    rtn = rtn.replace(needle, replacement);
  }
  return rtn;
}

function htmlspecialchars(str) {
  var string = str;
  var special = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;',
  };
  for (var i in special) string = str_replace(i, special[i], string);
  return string;
}

function val_format(no, dps, all_dps_from, grouping, format) {
  var num =
    typeof no === 'number'
      ? no
      : parseFloat(typeof no === 'string' ? str_replace(',', '', no) : no);
  var dps =
    typeof dps === 'number'
      ? dps
      : parseFloat(typeof dps === 'string' ? dps.replace(/[^0-9.]/g, '') : dps);
  if (typeof all_dps_from == 'undefined') all_dps_from = 0;
  if (typeof grouping == 'undefined') grouping = true;
  if (typeof format == 'undefined') format = false;
  if (isNaN(num)) return no;
  if (!isFinite(num)) return '&infin;';
  if (typeof dps === 'undefined' || isNaN(dps)) {
    dps = null;
  }

  if (dps !== null) {
    num = Math.round(num * Math.pow(10, dps)) / Math.pow(10, dps);
    dps = Math.ceil(dps);
    num = Math.round(num * Math.pow(10, dps)) / Math.pow(10, dps);
  }

  num += '';
  var x = num.split('.');
  var whole = x[0];
  var frac = x.length > 1 && dps != 0 ? x[1] : '';
  if (grouping) {
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(whole)) whole = whole.replace(rgx, '$1' + ',' + '$2');
  }
  if (frac == '' && dps !== null && dps > 0 && all_dps_from == 0) frac = '0';
  if (frac == '') {
    return whole;
  } else if (frac == '0') {
    return (
      whole +
      (format ? '<span class="dec">' : '') +
      '.' +
      str_repeat('0', dps) +
      (format ? '</span>' : '')
    );
  } else if (dps === null) {
    return whole + (format ? '<span class="dec">' : '') + '.' + frac + (format ? '</span>' : '');
  } else {
    var dec = parseFloat(frac) / Math.pow(10, frac.length);
    var buffer = '';
    var rtn = whole + (format ? '<span class="dec">' : '') + '.';
    dec = Math.round(dec * Math.pow(10, dps)) + '';
    if (Math.max(frac.length, dps) > dec.length)
      for (var i = 0; i < Math.max(frac.length, dps) - dec.length; i++) rtn += '0';
    frac = '';
    for (var i = 0; i < dec.length; i++) {
      if (dec.charAt(i) == '0' && (i < all_dps_from || frac.length < all_dps_from)) {
        buffer += '0';
      } else {
        frac += buffer + dec.charAt(i);
        buffer = '';
      }
    }
    return rtn + frac + (format ? '</span>' : '');
  }
}

function refresh_credits() {
  bb_get(site_url + 'ajax/get_credits/', function (json) {
    if (typeof json.credits != 'number') return;
    $('#current_credits').empty();
    switch (json.credits) {
      case 0:
        $('#current_credits').text('no credits');
        break;
      case 1:
        $('#current_credits').text('1 credit');
        break;
      default:
        $('#current_credits').text(json.credits + ' credits');
        break;
    }
  });
}

function log() {
  if (typeof console != 'undefined' && arguments.length > 0) {
    for (var i in arguments) console.log(arguments[i]);
  }
}

function check_announcement() {
  $.ajax({
    url: site_url + 'ajax/get_message_alerts',
    success: function (getReturn) {
      var alert = $('#message_alerts');
      if (typeof getReturn.msg !== 'undefined' && getReturn.msg !== '') {
        alert.parent().addClass('notification warning vv-p-20').removeClass('hide');
        alert.text(getReturn.msg);
      } else {
        alert.parent().removeClass('notification warning vv-p-20').addClass('hide');
        alert.text('');
      }
    },
  });
}

function show_plus(id) {
  $('#img_' + id).css('display', 'block');
}

function hide_plus(id) {
  $('#img_' + id).css('display', 'none');
}

function focus_select(elements) {
  $(elements).focus(function () {
    $(this)
      .select()
      .one('mouseup', function (e) {
        $(this).off('keyup');
        e.preventDefault();
      })
      .one('keyup', function () {
        $(this).select().off('mouseup');
      });
  });
}

function current_tab(tab) {
  return $('.tabcontainer[data-tabs=' + tab + '] .tabactive').data('tab');
}

function paging(target, page, setup) {
  var paging_count = 1;
  var last;

  var pageButtonClass =
    'border-0 border-r border-t border-b border-grey-200 inline-block text-center px-4 py-2 lg:px-2 lg:py-1 md:flex items-center flex text-xs text-black';
  var limitSelectClass =
    'text-xs rounded-md h-full bg-transparent border border-grey-200 cursor-pointer pl-1 pr-2 py-1 text-black';

  if (typeof setup != 'undefined') paging_setup[target] = setup;

  if (typeof paging_setup[target] == 'undefined') alert('No setup for paging: ' + target);

  var config = paging_setup[target];
  var lmt = typeof config.limit == 'number' && config.limit > 0 ? config.limit : config_rows_qty;
  if (max_rows_qty < lmt) lmt = max_rows_qty;

  params = '&page=' + page + '&limit=' + lmt;
  ajaxParams = {
    async: true,
    url: config.event.url + params,
    beforeSend: config.event.loadingCall,
    success: function (response, status, xhr) {
      var possibles = ['.pagination[data-table=' + target + ']', '#' + target, '.' + target];
      var paging_div = false;
      for (var p in possibles) {
        if ($(possibles[p]).length > 0) {
          paging_div = possibles[p];
          break;
        }
      }
      if (paging_div === false) return;

      $(paging_div).empty();

      config.event.afterCall(response);
      config.total = 0;

      switch (xhr.getResponseHeader('content-type')) {
        case 'application/xml':
        case 'text/xml':
          if ($(response).find('base').length > 0) {
            if (
              typeof $(response).find('base').attr('ttl_cnt_capped') != 'undefined' &&
              $(response).find('base').attr('ttl_cnt_capped')
            )
              config.total = $(response).find('base').attr('ttl_cnt_capped');
            else if (
              typeof $(response).find('base').attr('ttl_cnt') != 'undefined' &&
              $(response).find('base').attr('ttl_cnt')
            )
              config.total = $(response).find('base').attr('ttl_cnt');
          }
          break;
        case 'application/json':
          if (typeof response == 'object' && response !== null) {
            if (typeof response.ttl_cnt_capped != 'undefined')
              config.total = response.ttl_cnt_capped;
            else if (typeof response.ttl_cnt != 'undefined') config.total = response.ttl_cnt;
          }
          break;
      }

      paging_count = Math.ceil(config.total / lmt);
      if (typeof config.total_limit != 'undefined' && config.total > config.total_limit)
        paging_count = Math.floor(config.total_limit / lmt);

      if (typeof config.show_count == 'string' && config.show_count !== false) {
        $(paging_div).append('<span class="' + pageButtonClass + ' total" />');
        $(paging_div)
          .find('.total')
          .append(config.total + ' ');
        if (config.show_count.indexOf('/') > -1) {
          if (config.total == 1)
            $(paging_div)
              .find('.total')
              .append(config.show_count.substring(0, config.show_count.indexOf('/')));
          else
            $(paging_div)
              .find('.total')
              .append(config.show_count.substring(config.show_count.indexOf('/') + 1));
        } else if (config.show_count.indexOf('(') > -1) {
          if (config.total == 1)
            $(paging_div)
              .find('.total')
              .append(
                config.show_count.substring(0, config.show_count.indexOf('(')) +
                  config.show_count.substring(config.show_count.indexOf(')') + 1),
              );
          else
            $(paging_div)
              .find('.total')
              .append(config.show_count.replace('(', '').replace(')', ''));
        } else {
          $(paging_div).find('.total').append(config.show_count);
        }
      }

      $(paging_div).append("<div class='paging_pages flex my-1 justify-center'></div>");
      var paging_pages = paging_div + ' .paging_pages';

      if (paging_count > 1) {
        var preActive = true;
        if (page <= 1) {
          pre = 1;
          preActive = false;
        } else {
          pre = page - 1;
        }
        if (typeof config.first != 'undefined' && config.first !== false) {
          $(paging_pages).append('<a class="' + pageButtonClass + '">' + config.first + '</a>');
          if (preActive === true) $(paging_pages).find('a:last-child').data('page', 1);
          else $(paging_pages).find('a:last-child').addClass('faded');
        }
        if (typeof config.pre != 'undefined' && config.pre !== false) {
          $(paging_pages).append('<a class="' + pageButtonClass + '">' + config.pre + '</a>');
          if (preActive === true) $(paging_pages).find('a:last-child').data('page', pre);
          else $(paging_pages).find('a:last-child').addClass('faded');
        }
        if (typeof config.pages != 'undefined' && config.pages !== false) {
          if (typeof config.page_cnt != 'undefined') {
            pg_count = Math.min(paging_count, config.page_cnt);
            pg_start = Math.max(1, page - Math.floor(pg_count / 2));
            mid_start = Math.round(Math.min(pg_start, paging_count + 1 - pg_count));

            if (
              typeof config.pages != 'undefined' &&
              typeof config.page_start != 'undefined' &&
              config.page_start == true
            ) {
              if (pg_start > 1 && mid_start > 1)
                $(paging_pages).append('<a class="' + pageButtonClass + '" data-page="1">1</a>');
              if (pg_start == 3 && mid_start > 2)
                $(paging_pages).append('<a class="' + pageButtonClass + '" data-page="2">2</a>');
              if (pg_start > 3 && mid_start != 1)
                $(paging_pages).append(
                  '<span class="' + pageButtonClass + ' faded">&hellip;</span>',
                );
            }

            for (var i = mid_start; i < Math.min(pg_start + pg_count, paging_count + 1); i++) {
              $(paging_pages).append(
                '<a class="' + pageButtonClass + '" data-page="' + i + '">' + i + '</a>',
              );
              if (page == i) $(paging_pages).find('a:last-child').addClass('page_active');
            }

            if (
              typeof config.pages != 'undefined' &&
              typeof config.page_end != 'undefined' &&
              config.page_end == true
            ) {
              if (pg_start + pg_count < paging_count - 1)
                $(paging_pages).append(
                  '<span class="' + pageButtonClass + ' faded">&hellip;</span>',
                );
              if (pg_start + pg_count == paging_count - 1)
                $(paging_pages).append(
                  '<a class="' +
                    pageButtonClass +
                    '" data-page="' +
                    (paging_count - 1) +
                    '">' +
                    (paging_count - 1) +
                    '</a>',
                );
              if (pg_start + pg_count <= paging_count)
                $(paging_pages).append(
                  '<a class="' +
                    pageButtonClass +
                    '" data-page="' +
                    paging_count +
                    '">' +
                    paging_count +
                    '</a>',
                );
            }
          } else {
            for (var i = 1; i <= Math.ceil(paging_count); i++) {
              $(paging_pages).append(
                '<a class="' + pageButtonClass + '" data-page="' + i + '">' + i + '</a>',
              );
              if (page == i) $(paging_pages).find('a:last-child').addClass('page_active');
            }
          }
        }
        var nextActive = true;
        if (page >= paging_count) {
          next = 1;
          nextActive = false;
        } else {
          next = parseInt(page) + 1;
        }
        if (typeof config.next != 'undefined' && config.next !== false) {
          $(paging_pages).append('<a class="' + pageButtonClass + '">' + config.next + '</a>');
          if (nextActive === true) $(paging_pages).find('a:last-child').data('page', next);
          else $(paging_pages).find('a:last-child').addClass('faded');
        }
        if (typeof config.last != 'undefined' && config.last !== false) {
          $(paging_pages).append('<a class="' + pageButtonClass + '">' + config.last + '</a>');
          if (nextActive === true)
            $(paging_pages).find('a:last-child').data('page', Math.ceil(paging_count));
          else $(paging_pages).find('a:last-child').addClass('faded');
        }
      }

      if (
        config.limiter !== undefined &&
        config.limiter !== false &&
        config.total > 10 &&
        max_rows_qty > 10
      ) {
        var limits = [10, 25, 50, 100, 250].sort(function (a, b) {
          return a - b;
        });

        $(paging_div).append(
          '<div class="limiter flex mt-4 mb-5 lg:my-1 md:space-x-6 justify-center ml-2"/>',
        );
        $(paging_div)
          .find('.limiter')
          .append(
            '<select class="' + limitSelectClass + '" data-limit="' + lmt * (page - 1) + '"/>',
          );

        for (var index in limits) {
          $(paging_div)
            .find('.limiter select')
            .append(
              '<option value="' +
                limits[index] +
                '">' +
                Math.min(limits[index], config.total, max_rows_qty) +
                ' rows</option>',
            );

          if (
            limits[index] === lmt ||
            (limits[index] >= max_rows_qty && limits[index] < lmt) ||
            (limits[index] >= config.total && limits[index] < lmt)
          ) {
            $(paging_div).find('.limiter select option:last-child').prop('selected', true);
          }

          if (limits[index] >= config.total || limits[index] === max_rows_qty) break;
        }
      }
    },
  };
  if (typeof config.posts != 'undefined' || $.isEmptyObject(config.posts)) {
    ajaxParams['type'] = 'POST';
    ajaxParams['data'] = config.posts;
  }
  bb_ajax(ajaxParams);
}

function buildPagination(pagination_object, total_results, current_page, current_limit) {
  var pageButtonClass =
    'border-0 border-r border-t border-b border-grey-200 inline-block text-center px-4 py-2 lg:px-2 lg:py-1 md:flex items-center text-xs text-black';
  var limitSelectClass =
    'text-xs rounded-md h-full bg-white border border-grey-200 cursor-pointer pl-1 pr-2 py-1';

  current_limit = current_limit || 10;

  if (current_page === undefined || current_page === '') {
    current_page = 1;
  }

  current_page = parseInt(current_page);
  var backSelectorClass = current_page === 1 ? pageButtonClass + ' faded' : pageButtonClass;

  var page_numbers = $(
    '<div class="flex flex-row items-center lg:items-stretch justify-center lg:justify-end select-none my-1"></div>',
  );
  page_numbers.append(
    '<a data-page="' +
      (current_page - 1) +
      '" class="' +
      backSelectorClass +
      '"><i class="fa fa-angle-left" aria-hidden="true"/></a>',
  );
  var total_pages = Math.ceil(total_results / current_limit) + 1;

  // change buffer size base on screen size
  var buffer_size = $(window).width() < 768 ? 1 : 2;
  var min_page_num_to_show = current_page - buffer_size,
    max_page_num_to_show = current_page + buffer_size,
    range = [];

  for (var i = 1; i <= total_pages; i++) {
    if (i === 1 || i === total_pages || (i >= min_page_num_to_show && i <= max_page_num_to_show)) {
      range.push(i);
    }
  }

  var previous_value;
  range.forEach(function (value) {
    // draw active page number
    if (current_page === value) {
      page_numbers.append(
        '<a data-page="' +
          value +
          '" class="' +
          pageButtonClass +
          ' page_active">' +
          value +
          '</a>',
      );
      previous_value = value;
      return;
    }

    if (previous_value) {
      if (value - previous_value === 2) {
        page_numbers.append(
          '<a data-page="' +
            (previous_value + 1) +
            '" class="' +
            pageButtonClass +
            '">' +
            (previous_value + 1) +
            '</a>',
        );
      } else if (value - previous_value !== 1) {
        page_numbers.append('<a class="' + pageButtonClass + ' faded">...</a>');
      }
    }

    page_numbers.append(
      '<a data-page="' + value + '" class="' + pageButtonClass + '">' + value + '</a>',
    );
    previous_value = value;
  }, this);

  page_numbers.append(
    '<a ' +
      (current_page >= total_pages - 1
        ? 'class="' + pageButtonClass + ' faded"'
        : 'class="' + pageButtonClass + '" data-page="' + (current_page + 1) + '"') +
      '><i class="fa fa-angle-right" aria-hidden="true"/></a>',
  );

  pagination_object
    .empty()
    .append(page_numbers)
    .append(
      '<div class="limiter flex mt-4 mb-5 lg:my-1 space-x-4"><select class="' +
        limitSelectClass +
        '" data-limit="0"></select></div>',
    );

  var limit_select = pagination_object.find('select');

  var available_limits = [10, 25, 50, 100, 250];

  VV.traverse(available_limits, function (index, element) {
    limit_select.append(
      '<option value="' +
        element +
        '" ' +
        (current_limit == element ? 'selected' : '') +
        '>' +
        element +
        ' rows</option>',
    );
  });
}

function paging_limit(target, lim, top) {
  var config = paging_setup[target];

  if (config.limit === 0 || typeof config.limit === 'undefined') {
    bb_get(window.site_url + 'ajax/set_table_rows/?rows=' + parseInt(lim), function () {});
    config_rows_qty = parseInt(lim);
  } else {
    config.limit = parseInt(lim);
    limit[target.slice(0, 4) === 'pag_' ? target.substring(4) : target] = parseInt(lim);
  }
  var page = Math.floor(top / parseInt(lim)) + 1;
  paging(target, page, config);
}

function pagify(div, overrides) {
  if (typeof action[div] != 'undefined' && typeof after[div] != 'undefined') {
    var actn = action[div];
    if (typeof nocache[div] != 'undefined' && nocache[div]) actn += '&cache=' + nowStamp();
    opts = {
      pages: true,
      pre: '<i class="fa fa-angle-left" aria-hidden="true">',
      next: '<i class="fa fa-angle-right" aria-hidden="true">',
      event: {
        url:
          typeof $("table[data-table='" + div + "']").attr('data-url') !== 'undefined'
            ? $("table[data-table='" + div + "']").attr('data-url')
            : site_url + 'ajax/?action=' + actn,
        afterCall: after[div],
      },
      page_cnt: window.innerWidth < 800 ? 3 : 9,
      page_start: true,
      page_end: true,
      limiter: true,
    };

    // get the field name, discarded sorting direction
    var sort_str = encodeURIComponent(sorting[div]).split('%20')[0];

    // if field is null, don't do anything
    if (sort_str === 'null') {
      return false;
    }

    if (typeof limit[div] != 'undefined') opts.limit = limit[div];
    if (typeof total_limit[div] != 'undefined') opts.total_limit = total_limit[div];
    if (typeof before[div] != 'undefined') opts.event.loadingCall = before[div];
    if (typeof sorting[div] != 'undefined')
      opts.event.url += '&sort=' + encodeURIComponent(sorting[div]);
    if (typeof limiter[div] != 'undefined') opts.limiter = limiter[div];
    if (typeof overrides == 'object') {
      for (var o in overrides) {
        opts[o] = overrides[o];
      }
    }
    if (typeof posts !== 'undefined' && typeof posts[div] !== 'undefined')
      opts['posts'] = posts[div];
    if (typeof show_count !== 'undefined' && typeof show_count[div] !== 'undefined')
      opts['show_count'] = show_count[div];
    if (
      $('table').filter(function (i) {
        return $(this).data('table') == div;
      }).length > 0
    )
      paging(div, 1, opts);
    else paging('pag_' + div, 1, opts);
  }
}

function searchClean(str, allowSlashes) {
  if (allowSlashes === true) return str_replace('#', '', str_replace('%', '', str));
  else
    return str_replace(
      '#',
      '',
      str_replace('%', '', str_replace("'", "\\'", str_replace('"', '\\"', str))),
    );
}

function nowStamp() {
  var now = new Date();
  return now.getTime();
}

function quickAfterCall(id, json) {
  if (json.notification && json.notification.toLowerCase() === 'invalid search') {
    $('table')
      .filter(function (i) {
        return $(this).data('table') == 'quick' + id;
      })
      .find('tbody')
      .html('<tr><td colspan="' + colspan + '">No results found</td></tr>');

    $('#quick_search').css('background', '#FFFFFF');

    $('#quick_links a span').html(0);
    return;
  }

  var total = 0;
  var order = sorting['quick' + id];
  var odd = false;
  var skipto = '';
  var navto = '';
  var lastlink = '';
  var mapship = false;
  var mapsearch = false;
  var maptype = id.slice(0, -1);
  var mapid = false;
  var tidy_up = true;

  $('#quick' + id + '_link .count').empty();
  $('table')
    .filter(function (i) {
      return $(this).data('table') == 'quick' + id;
    })
    .find('tbody')
    .empty();

  if (last_quick != last_quick_search)
    for (var i in quickfilters)
      if (quickfilters[i] != id) dot_animate_start('#quick' + quickfilters[i] + '_link .count');

  if (json === null /*|| typeof(json.ttl_cnt) == 'undefined'*/) {
    var colspan = count_cols(
      $('table')
        .filter(function (i) {
          return $(this).data('table') == 'quick' + id;
        })
        .find('.sorting_top'),
    );
    $('table')
      .filter(function (i) {
        return $(this).data('table') == 'quick' + id;
      })
      .find('tbody')
      .html('<tr><td colspan="' + colspan + '">No results found</td></tr>');
  } else {
    total = json.ttl_cnt || 0;
    if (total == 0) {
      var colspan = count_cols(
        $('table')
          .filter(function (i) {
            return $(this).data('table') == 'quick' + id;
          })
          .find('.sorting_top'),
      );
      $('table')
        .filter(function (i) {
          return $(this).data('table') == 'quick' + id;
        })
        .find('tbody')
        .html('<tr><td colspan="' + colspan + '">No results found</td></tr>');
    } else {
      for (var i in json.data) {
        var result = json.data[i];
        var row = $('<tr data-id="' + result.id + '" />');
        mapid = result.id;

        if (odd) $(row).addClass('odd');
        odd = !odd;

        switch (id) {
          case 'vessels':
          case 'dead':
          case 'ex':
            lastlink = result.ship_href;
            maptype = 'vessel';
            mapship = result.id;

            if (this_page == 'compare') {
              $(row).append('<td class="action"><a href="#" class="compare_add">Compare</a></td>');
              if (
                $('#ship_compare td').filter(function (i) {
                  return $(this).data('id') == result.id;
                }).length > 0
              ) {
                $(row).find('td:last-child').hide();
              }
            } else if (this_page === 'portfolio') {
              if (editable) {
                $(row).append('<td class="action"><a href="#" class="portfolio_add">Add</a></td>');
              } else {
                $(row).append('<td>&nbsp;</td>');
              }
            }

            $(row).append(
              '<td data-field="ship_name" title="' +
                result.ship_name +
                '"><div class="fh"><a class="quicklink" data-subject="vessel" data-id="' +
                result.id +
                '" href="' +
                lastlink +
                '">' +
                result.ship_name +
                '</a></div></td>',
            );

            if (id == 'ex') {
              $(row).append(
                '<td data-field="ex_name" title="' +
                  result.ex_name +
                  '"><div class="fh">' +
                  result.ex_name +
                  '</div></td>',
              );
            }

            $(row).append(
              '<td class="al" data-field="ship_type_name" title="' +
                result.ship_type_name +
                '"><div class="fh">' +
                result.ship_type_name +
                '</div></td>',
            );
            $(row).append(
              '<td class="ar" data-field="ship_size"><div class="fh">' +
                result.ship_size +
                '</div></td>',
            );
            $(row).append(
              '<td class="ar" data-field="ship_size_alt"><div class="fh">' +
                result.ship_size_alt +
                '</div></td>',
            );
            $(row).append('<td class="ac" data-field="build_year">' + result.build_year + '</td>');
            $(row).append(
              '<td class="ac" data-field="yard_country">' +
                result.ship_builder_country_flag_position +
                '</td>',
            );

            if (result.yard_name == redact) {
              $(row).append('<td data-field="yard_name">' + redact + '</td>');
            } else if (result.ship_builder_name) {
              $(row).append(
                '<td data-field="yard_name" title="' +
                  result.ship_builder_name +
                  (result.ship_builder_country_id && result.ship_builder_country_flag != redact
                    ? ', ' + result.ship_builder_country_name
                    : '') +
                  '"><div class="fh"><a class="quicklink" data-subject="builder" data-id="' +
                  result.yard_id +
                  '" href="' +
                  result.ship_builder_url +
                  '">' +
                  result.ship_builder_name +
                  '</a></div></td>',
              );
            } else {
              $(row).append('<td data-field="yard_name">&nbsp;</td>');
            }

            $(row).append(
              '<td class="ac" data-field="operator_country">' +
                result.operator_country_flag_position +
                '</td>',
            );

            if (result.operator_name == redact) {
              $(row).append('<td data-field="operator_name">' + redact + '</td>');
            } else if (result.operator_name) {
              $(row).append(
                '<td data-field="operator_name" title="' +
                  result.operator_name +
                  (result.operator_country_id ? ', ' + result.operator_country : '') +
                  '"><div class="fh"><a class="quicklink" data-subject="operator" data-id="' +
                  result.operator_id +
                  '" href="' +
                  result.operator_url +
                  '">' +
                  result.operator_name +
                  '</a></div></td>',
              );
            } else {
              $(row).append('<td data-field="operator_name">&nbsp;</td>');
            }

            break;

          case 'operators':
          case 'owners':
            lastlink = site_url + result.operator_href;

            // SS Only until ready
            var ss = result.operator_type === 879;

            if (id == 'owners') {
              /*
               * VIT-8205 f.owner_id
               */
              mapsearch = {
                field: 'f.owner_id',
                rows: [{ criteria: result.id, operator: 'equals' }],
              };
            } else if (result.operator_typeid == 861) {
              mapsearch = {
                field: 'lo.operator_id',
                rows: [{ criteria: result.id, operator: 'equals' }],
              };
            } else {
              /*
               * VIT-8205 f.operator_id
               */
              mapsearch = {
                field: 'beneficial.operator_id',
                rows: [{ criteria: result.id, operator: 'equals' }],
              };
            }

            if (this_page === 'portfolio') {
              if (editable) {
                $(row).append('<td class="action"><a href="#" class="portfolio_add">Add</a></td>');
              } else {
                $(row).append('<td>&nbsp;</td>');
              }
            }

            $(row).append(
              '<td class="ac" data-field="operator_country">' +
                result.operator_country_flag_position +
                '</td>',
            );

            if (result.operator_name == redact)
              $(row).append('<td class="al" data-field="operator_name">' + redact + '</td>');
            else if (typeof result.operator_name == 'string' && result.operator_name != '') {
              $(row).append(
                '<td class="al" data-field="operator_name" title="' +
                  result.operator_name +
                  (result.operator_flag ? ', ' + result.operator_country : '') +
                  '"><div class="fh"><a class="quicklink ' +
                  (ss ? 'seasure_only' : '') +
                  '" data-id="' +
                  result.id +
                  '" href="' +
                  lastlink +
                  '">' +
                  result.operator_name +
                  '</a></div></td>',
              );

              if (id == 'owners')
                $(row).find('td:last-child a.quicklink').attr('data-subject', 'owner');
              else if (result.operator_typeid == 861)
                $(row).find('td:last-child a.quicklink').attr('data-subject', 'pool');
              else $(row).find('td:last-child a.quicklink').attr('data-subject', 'operator');
            } else {
              $(row).append('<td class="al" data-field="operator_name">&nbsp;</td>');
            }

            if (result.operator_name2 == redact)
              $(row).append('<td data-field="operator_name2">' + redact + '</td>');
            else if (typeof result.operator_name2 == 'string' && result.operator_name2 != '')
              $(row).append(
                '<td class="al" data-field="operator_name2" title="' +
                  result.operator_name2 +
                  '"><div class="fh">' +
                  result.operator_name2 +
                  '</div></td>',
              );
            else $(row).append('<td class="al" data-field="operator_name2">&nbsp;</td>');

            $(row).append(
              '<td class="ar" data-field="operator_typedesc">' + result.operator_type_img + '</td>',
            );
            $(row).append(
              '<td class="al" data-field="operator_typedesc" title="' +
                result.operator_type_desc +
                '"><div class="fh">' +
                result.operator_type_desc +
                '</div></td>',
            );
            break;

          case 'builders':
            lastlink = site_url + result.ship_builder_href;
            mapsearch = {
              field: 'f.ship_builder_id',
              rows: [{ criteria: result.id, operator: 'equals' }],
            };

            if (this_page === 'portfolio') {
              if (editable) {
                $(row).append('<td class="action"><a href="#" class="portfolio_add">Add</a></td>');
              } else {
                $(row).append('<td>&nbsp;</td>');
              }
            }

            $(row).append(
              '<td class="ac" data-field="ship_builder_country">' +
                result.ship_builder_flag_position +
                '</td>',
            );

            if (result.ship_builder_name == redact)
              $(row).append('<td data-field="ship_builder_name">' + redact + '</td>');
            else if (typeof result.ship_builder_name == 'string' && result.ship_builder_name != '')
              $(row).append(
                '<td class="al" data-field="ship_builder_name" title="' +
                  result.ship_builder_name +
                  (result.ship_builder_country_id ? ', ' + result.ship_builder_country : '') +
                  '"><div class="fh"><a class="quicklink" data-subject="builder" data-id="' +
                  result.id +
                  '" href="' +
                  lastlink +
                  '">' +
                  result.ship_builder_name +
                  '</a></div></td>',
              );
            else $(row).append('<td class="al" data-field="ship_builder_name">&nbsp;</td>');

            if (result.ship_builder_region == redact)
              $(row).append('<td data-field="ship_builder_region">' + redact + '</td>');
            else if (
              typeof result.ship_builder_region == 'string' &&
              result.ship_builder_region != ''
            )
              $(row).append(
                '<td class="al" data-field="ship_builder_region" title="' +
                  result.ship_builder_region +
                  '"><div class="fh">' +
                  result.ship_builder_region +
                  '</div></td>',
              );
            else $(row).append('<td class="al" data-field="ship_builder_region">&nbsp;</td>');

            if (result.ship_builder_alternative == redact)
              $(row).append('<td data-field="ship_builder_alternative">' + redact + '</td>');
            else if (
              typeof result.ship_builder_alternative == 'string' &&
              result.ship_builder_alternative != ''
            )
              $(row).append(
                '<td class="al" data-field="ship_builder_alternative" title="' +
                  result.ship_builder_alternative +
                  '"><div class="fh">' +
                  result.ship_builder_alternative +
                  '</div></td>',
              );
            else $(row).append('<td class="al" data-field="ship_builder_alternative">&nbsp;</td>');

            $(row).append(
              '<td class="ar" data-field="ship_builder_type">' +
                result.ship_builder_type_img +
                '</td>',
            );
            $(row).append(
              '<td class="al" data-field="ship_builder_type" title="' +
                result.ship_builder_type +
                '"><div class="fh">' +
                result.ship_builder_type +
                '</div></td>',
            );

            if (this_page == 'at') {
              if (result.ship_builder_polygon_center == null) {
                var pointGeo = ['(91', '181)'];
              } else {
                var pointGeo = result.ship_builder_polygon_center.split(' ');
              }
              builders[result.id] = {
                y: pointGeo[0].split('(').pop(),
                x: pointGeo[1].substring(0, pointGeo[1].length - 1),
              };
            }

            break;

          case 'portfolios':
            lastlink = site_url + result.portfolio_href;
            mapsearch = {
              field: 'pf.portfolio_id',
              rows: [{ criteria: result.id, operator: 'equals' }],
            };

            if (this_page === 'portfolio') {
              if (editable) {
                $(row).append('<td class="action"><a href="#" class="portfolio_add">Add</a></td>');
              } else {
                $(row).append('<td>&nbsp;</td>');
              }
            }

            $(row).append(
              '<td class="al" data-field="portfolio_name" title="' +
                result.portfolio_name +
                '"><div class="fh"><a class="quicklink pf" data-subject="portfolio" data-id="' +
                result.id +
                '" href="' +
                lastlink +
                '">' +
                result.portfolio_name +
                '</a></div></td>',
            );
            $(row).append(
              '<td class="al" data-field="contact_name" title="' +
                result.contact_name +
                '"><div class="fh">' +
                result.contact_name +
                '</div></td>',
            );
            $(row).append(
              '<td class="al" data-field="portfolio_shared">' + result.portfolio_shared + '</td>',
            );
            $(row).append('<td class="ar" data-field="ship_count">' + result.ship_count + '</td>');
            break;

          case 'ports':
            lastlink = site_url + result.port_href;
            mapsearch = {};
            var ss = result.port_type === 7819;

            if (this_page == 'at')
              ports[result.id] = {
                x: result.port_longitude,
                y: result.port_latitude,
                n: result.port_north,
                s: result.port_south,
                e: result.port_east,
                w: result.port_west,
              };

            $(row).append(
              '<td class="al" data-field="port_name"><div class="fh"><a class="quicklink' +
                (ss ? ' seasure_only' : '') +
                '" data-subject="port" data-id="' +
                result.id +
                '" href="' +
                lastlink +
                '">' +
                result.port_name +
                '</a></div></td>',
            );
            $(row).append(
              '<td class="al" data-field="port_type_name">' + result.port_typedesc + '</td>',
            );
            $(row).append('<td class="ac">' + result.port_flag_position + '</td>');
            $(row).append(
              '<td class="al" data-field="port_country"><div class="fh">' +
                result.port_country_name +
                '</div></td>',
            );
            $(row).append(
              '<td class="al" data-field="port_unctad">' + result.port_unctad + '</td>',
            );
            break;

          case 'gis':
            lastlink = site_url + result.offshore_name_href;
            mapsearch = {};
            gis[result.id] = result;
            $(row).append(
              '<td class="al" data-field="offshore_name"><div class="fh"><a class="quicklink" data-subject="gis" data-id="' +
                result.id +
                '" href="' +
                lastlink +
                '">' +
                result.offshore_name +
                '</a></div></td>',
            );
            $(row).append(
              '<td class="al" data-field="offshore_type">' + result.offshore_type + '</td>',
            );
            $(row).append('<td class="ac">' + result.offshore_flag_position + '</td>');
            $(row).append(
              '<td class="al" data-field="offshore_country_name"><div class="fh">' +
                result.offshore_country_name +
                '</div></td>',
            );
            $(row).append(
              '<td class="al" data-field="offshore_region_name"><div class="fh">' +
                result.offshore_region_name +
                '</div></td>',
            );
            break;
        }

        $('table')
          .filter(function (i) {
            return $(this).data('table') == 'quick' + id;
          })
          .find('tbody')
          .append(row);
      }
    }

    quicktabbed['quick' + id] = true;
    colour_sorted_column('quick' + id);

    $('#quick' + id + '_link .count')
      .empty()
      .text(' ' + total);

    if (typeof json.term != 'undefined') {
      for (var q in quickfilters) {
        if (
          typeof json.summary != 'undefined' &&
          typeof json.summary[quickfilters[q]] != 'undefined'
        ) {
          $('#quick' + quickfilters[q] + '_link .count')
            .empty()
            .text(json.summary[quickfilters[q]]);

          if (total == 0 && skipto == '' && json.summary[quickfilters[q]] > 0)
            skipto = quickfilters[q];

          if (navto === '' && json.summary[quickfilters[q]] == 1) navto = lastlink;
          else if (json.summary[quickfilters[q]] > 0) navto = false;
        }
      }

      if (quick_go && navto !== '' && navto !== false) {
        tidy_up = false;

        if (this_page != 'at') window.location = lastlink;
        else quick_add(maptype, mapid);
      }

      if (skipto != '' && tidy_up) {
        location.href = '#quick' + skipto;
        changeTab('quick' + skipto);
        pagify('quick' + skipto);
      }
    }
  }
  clearTimeout(quick_timeout);
  stop_quick_timeout = true;

  if (last_quick_search === json.term) {
    if (tidy_up) {
      quickResultsShow();
    }

    $('#quick_search').css('background', '#FFFFFF');
  }
}

function get_quick_search(last_quick_search) {
  if (last_quick_search === last_quick) {
  } else if (last_quick_search === true) {
    // Todo : this if block of code does not appear to be accessible
    var current = $('#quick_tabs .tabactive').attr('id').slice(0, -4);

    if ($('#quick_search').val()) {
      $('#quick_search').css(
        'background',
        'url(' + img_url + 'ajax-loader.gif) no-repeat 98% center transparent',
      );
      $('#quick_search').css('background-color', '#FFE3E5');
    }

    for (var i in quickfilters) {
      action['quick' + quickfilters[i]] = 'quick_search&blanksearch=1&filter=' + quickfilters[i];
      quicktabbed['quick' + quickfilters[i]] = false;
    }

    pagify(current);
  } else {
    last_quick = last_quick_search; //  Modify this early so that concurrent check doesn't drop out too soon.
    var search_term = searchClean($('#quick_search').val(), true);

    if (search_term.length >= 2 && search_term == last_quick_search) {
      var current = $('#quick_tabs .tabactive').attr('id').slice(0, -4);

      $('#quick_search').css(
        'background',
        'url(' + img_url + 'ajax-loader.gif) no-repeat 98% center transparent',
      );
      $('#quick_search').css('background-color', '#FFE3E5');

      for (var i in quickfilters) {
        action['quick' + quickfilters[i]] =
          'quick_search&term=' +
          encodeURIComponent(last_quick_search) +
          '&filter=' +
          quickfilters[i];
        quicktabbed['quick' + quickfilters[i]] = false;
      }

      pagify(current);
    }
  }
}

function quickResultsShow() {
  $('.search_con').slideDown(200);

  if ($('#search_close').length && $('#search_close').html().indexOf('close') == -1) {
    $('#search_close').fadeOut(200, function () {
      $('#search_close').html('<a href="#" class="search_bar_close">Close</a>').fadeIn(200);
    });
  }

  resizeTab();
}

function quickResultsHide() {
  $('.search_con').slideUp(200);

  if ($('#search_close').length && $('#search_close').html() != search_bar_right) {
    $('#search_close').fadeOut(200, function () {
      $('#search_close').html(search_bar_right).fadeIn(200);
    });
  }

  $('#quick_search').blur();
}

function resizeTab(el) {
  if (typeof el == 'undefined') {
    var tabactive = $('.tabactive').toArray();

    for (var i in tabactive) {
      resizeTab(tabactive[i]);
    }

    return;
  }

  if ($(el).hasClass('tabactive') || $(el).closest('.tabcontent').hasClass('tabactive')) {
    setTimeout(function () {
      $(el).closest('.tabcontainer').css('min-height', $(el).height());
    }, 100);
  }
}

function resizeCharts(tabcontent) {
  if (typeof tabcontent == 'undefined' || tabcontent.length == 0) {
    return false;
  }

  var charts = $(tabcontent).find('.chart_wrapper .chart_container, .chart_wrapper');

  if (charts.length > 0) {
    $(charts).each(function () {
      if (typeof $(this).highcharts() == 'object') {
        $(this).highcharts().reflow();
      }
    });
  }

  if (window.Highcharts) {
    Highcharts.charts.forEach(function (chart) {
      var id = tabcontent[0].id;

      if (chart && id && $(chart.container).parents('#' + id).length) {
        chart.reflow();
      }
    });
  }

  return true;
}

function findSeparatorIndex(el, seperator) {
  return $(el)
    .attr('id')
    .substring(
      0,
      $(el)
        .attr('id')
        .indexOf(seperator || '_'),
    );
}

function changeTab(el) {
  if ($.isEmptyObject(defaulttab)) {
    var all_tabs = $('.tabcontainer').toArray();
    for (var t in all_tabs) {
      if (all_tabs.hasOwnProperty(t)) {
        var tab_active = $(all_tabs[t])
          .children($(all_tabs[t]).children('.tabactive').length > 0 ? '.tabactive' : '.tabcontent')
          .first();
        if ($(all_tabs[t]).data('tabs')) {
          var tab_parent = $(all_tabs[t]).data('tabs');
          var tab_name = $(tab_active).data('tab');
        } else {
          var tab_parent = $(all_tabs[t])
            .attr('id')
            .substring(0, $(all_tabs[t]).attr('id').indexOf('_'));
          var tab_name = $(tab_active)
            .attr('id')
            .substring(0, $(tab_active).attr('id').indexOf('_'));
        }
        defaulttab[tab_parent] = tab_name;
      }
    }
  }
  if (typeof el == 'string') {
    var tab = decodeURIComponent(el).replace(/(:)\1+/g, ':');

    if (tab === ':') {
      tab = '';
    }

    var el = '';
    while (el != tab) {
      el = '' + tab;
      if (tab.substring(0, 1) == '!') tab = tab.substring(1);
      if (tab.substring(0, 1) == '/') tab = tab.substring(1);
      if (tab.substring(tab.length - 1) == '/') tab = tab.substring(0, tab.length - 1);
    }
    if (tab == '') return false;
  } else if ($(el).data('tab')) {
    var tab = $(el).data('tab');
  } else {
    var tab = $(el).attr('id');
  }

  if (tab === undefined) {
    return;
  }

  var tabs = tab.split('/');
  var skip = true;

  for (var t in tabs) {
    tab = tabs[t];

    try {
      if (
        $('.tabcontent').filter(function (i) {
          return $(this).data('tab') == tab;
        }).length == 0 &&
        $('#' + tab + '_tab').length == 0
      )
        continue;
      skip = false;

      if (
        $('.tabcontent').filter(function (i) {
          return $(this).data('tab') == tab;
        }).length > 0
      ) {
        var tab_parent = $('.tabcontent')
          .filter(function (i) {
            return $(this).data('tab') == tab;
          })
          .closest('.tabcontainer')
          .data('tabs');
        var tabcontainer = $('.tabcontainer').filter(function (i) {
          return $(this).data('tabs') == tab_parent;
        });
        var tablinks = $('.tabstrip')
          .filter(function (i) {
            return $(this).data('tabs') == tab_parent;
          })
          .find('a, div.tablinks');
        var tablink = $('.tabstrip a:not(.more_info), .tabstrip div.tablinks').filter(function (i) {
          return $(this).data('tab') == tab;
        });
        var tabcontent = $('.tabcontent').filter(function (i) {
          return $(this).data('tab') == tab;
        });
      } else {
        // Legacy method, migrating to data attributes
        var tab_parent = $('#' + tab + '_tab')
          .closest('.tabcontainer')
          .attr('id')
          .substring(
            0,
            $('#' + tab + '_tab')
              .parent()
              .attr('id')
              .indexOf('_'),
          );
        var tabcontainer = $('#' + tab_parent + '_tabs');
        var tablinks = $('#' + tab_parent + '_links a');
        var tablink = $('#' + tab + '_link');
        var tabcontent = $('#' + tab + '_tab');
      }
    } catch (error) {
      window.location.replace('');
    }

    $(tabcontainer).children('.tabactive').removeClass('tabactive');
    $(tablinks).removeClass('active');
    $(tablink).addClass('active').blur();
    $(tabcontent).addClass('tabactive');
    resizeCharts(tabcontent);
    resizeTab();
    activetab[tab_parent] = tab;

    var subtabs = $(tabcontent).find('.tabstrip');
    if (subtabs.length > 0) {
      activesubtab = subtabs.children('.active').data('tab');
    }

    if (typeof tab_action == 'object' && typeof tab_action[tab] == 'function') tab_action[tab]();
  }

  if (skip) return false;

  var tablinks = $('.tabstrip a[href^=\\#], .tabstrip div.tablinks').toArray();
  for (var i in tablinks) {
    if ($(tablinks[i]).closest('.tabstrip').data('tabs'))
      var pid = $(tablinks[i]).closest('.tabstrip').data('tabs');
    else
      var pid = $(tablinks[i])
        .parent()
        .attr('id')
        .substring(0, $(tablinks[i]).parent().attr('id').indexOf('_'));

    var tl = '#!/';
    for (var t in activetab) {
      var addtab = '';

      if (t != pid && activetab[t] == defaulttab[t]) continue;
      else if (t != pid) addtab = activetab[t];
      else if ($(tablinks[i]).data('tab')) addtab = $(tablinks[i]).data('tab');
      else addtab = $(tablinks[i]).attr('id').substring(0, $(tablinks[i]).attr('id').indexOf('_'));

      tl += addtab + '/';
    }
    $(tablinks[i]).attr('href', tl);
  }

  var tl = '';
  for (var t in activetab) {
    if (activetab[t] == defaulttab[t]) continue;
    tl += activetab[t] + '/';
  }
  if (tl == '' && typeof el == 'string') tl = el;
  if (tl != '' && tl.substring(tl.length - 1) != '/') tl += '/';
  if (typeof $.history == 'object' && $.history !== null && tl != '') $.history.load('!/' + tl);
  $('#feedback_cont a').attr('href', '#!/feedback/' + tl);
}

function sendFeedback() {
  var message = $('#feedback_cont textarea').val();

  if (message == '' || message == feedback_default) {
    alert('Please enter your feedback');
  } else {
    bb_post(
      site_url + 'ajax/feedback/',
      {
        comment: $('#feedback_cont textarea').val(),
      },
      function (json) {
        switch (json.notification) {
          case 'empty':
            alert('Please enter your feedback');
            break;
          case 'success':
            alert('Your feedback has been sent');
            $('#feedback_link').removeClass('hover');
            $('#feedback_cont').slideUp(200);
            $('#feedback_cont textarea').val(feedback_default);
            break;
        }
      },
    );
  }

  return false;
}

function url_hyphenate(str) {
  var symbols = '/&_ ';

  for (var i = 0; i < symbols.length; i++) {
    str = str_replace(symbols.charAt(i), '-', str);
  }

  return str;
}

function clean_uri(uri) {
  return uri.replace(/[^A-Za-z0-9-]/g, '-');
}

function colspan_array(table, filter) {
  var columns = [];

  /**
   * AL: for lack of a better method, when appending a new row to the table in Trade or Pos List
   * the colspan is affected by the secondary tables inside the div. Now that's fine for Chrome and FF
   * but IE is an absolute joke and no longer the draws the table as expected
   */
  $(table).find('tr[data-map]').remove();

  var rows = $(table).is('tr') ? $(table).toArray() : $(table).find('tr').toArray();
  for (var r in rows) {
    var cells = $(rows[r]).find('th, td');
    if (typeof filter != 'undefined') cells = $(cells).filter(filter);
    cells = $(cells).toArray();

    var thesecolumns = [];
    for (var c in cells) thesecolumns.push(parseInt($(cells[c]).attr('colspan') || 1));

    var done = false;
    while (!done) {
      var c = 0;
      var l = thesecolumns.length;
      done = true;
      for (var t = 0; t < l; t++) {
        if (typeof columns[c] == 'undefined') {
          columns[c] = thesecolumns[t];
          c++;
          done = false;
        } else if (columns[c] == thesecolumns[t]) {
          c++;
        } else if (columns[c] > thesecolumns[t] && typeof thesecolumns[t + 1] != 'undefined') {
          thesecolumns[t] += thesecolumns[t + 1];
          thesecolumns.splice(t + 1, 1);
          done = false;
          l--;
        } else if (columns[c] < thesecolumns[t] && typeof columns[c + 1] == 'undefined') {
          columns[c] = thesecolumns[t];
          done = false;
        } else if (columns[c] < thesecolumns[t]) {
          columns[c] += columns[c + 1];
          columns.splice(c + 1, 1);
          done = false;
        }
      }
    }
  }
  return columns;
}

function tableloading(id, filter) {
  var colspan = count_cols(
    $('table').filter(function (i) {
      return $(this).data('table') == id;
    }),
    filter ||
      function (i) {
        return !$(this).hasClass('scroll_hide') && $(this).css('display') != 'none';
      },
  );
  $('table')
    .filter(function (i) {
      return $(this).data('table') == id;
    })
    .find('tbody')
    .empty()
    .append(
      '<tr><td colspan="' +
        colspan +
        '" class="odd"><img class="loading-animation medium center my-5" src="' +
        img_url +
        'loadingAnimation.gif" alt="Loading..." /></td></tr>',
    );
}

function count_cols(table, filter) {
  var array = colspan_array(table, filter);
  var sum = 0;
  for (var i in array) sum += array[i];
  return sum;
}

function scroll(dir, el) {
  var data_scroll = '';
  if (typeof el == 'string') data_scroll = el;
  else
    data_scroll =
      $(el).is('a') && !$(el).data('scroll')
        ? $(el)
            .closest('.scroll_' + dir)
            .data('scroll')
        : $(el).data('scroll');

  var rows = $('table')
    .filter(function (i) {
      return $(this).data('scroll') == data_scroll;
    })
    .find('tr')
    .toArray();

  var cols = colspan_array(
    $('table').filter(function (i) {
      return $(this).data('scroll') == data_scroll;
    }),
    '.scroll_col',
  );
  var before = 0;
  var showing = 0;
  var after = 0;
  var cells = $(rows[0]).find('.scroll_col').toArray();
  var col = 0;
  var thiscol = 0;

  for (var c in cells) {
    if (thiscol == 0) {
      if (!$(cells[c]).hasClass('scroll_hide')) showing++;
      else if (showing == 0) before++;
      else after++;
    }
    thiscol += parseInt($(cells[c]).attr('colspan') || 1);
    if (thiscol == cols[col]) {
      col++;
      thiscol = 0;
    }
  }

  switch (dir) {
    case 'first':
      if (before > 0) {
        after += before;
        before = 0;
      }
      break;
    case 'left':
      if (before > 0) {
        before--;
        after++;
      }
      break;
    case 'right':
      if (after > 0) {
        before++;
        after--;
      }
      break;
    case 'last':
      if (after > 0) {
        before += after;
        after = 0;
      }
      break;
    case 'restore':
      dir =
        typeof table_scroll_pos[data_scroll] == 'undefined'
          ? before
          : table_scroll_pos[data_scroll];
    // fall through
    default:
      dir = parseInt(dir);
      after -= dir - before;
      if (after < 0) {
        dir += after;
        after = 0;
      }
      before = dir;
      break;
  }

  for (var r in rows) {
    cells = $(rows[r]).find('.scroll_col').toArray();
    col = 0;
    thiscol = 0;
    for (var c in cells) {
      if (col < before || col >= before + showing) $(cells[c]).addClass('scroll_hide');
      else $(cells[c]).removeClass('scroll_hide');

      thiscol += parseInt($(cells[c]).attr('colspan') || 1);
      if (thiscol == cols[col]) {
        col++;
        thiscol = 0;
      }
    }
  }

  table_scroll_pos[data_scroll] = before;
  scroll_refresh();
  return false;
}

function scroll_refresh() {
  var tables = $('table[data-scroll]').toArray();
  var data_scroll = '';
  var left_img;
  var right_img;
  for (var t in tables) {
    data_scroll = $(tables[t]).data('scroll');

    left_img = $('.scroll_left')
      .filter(function (i) {
        return $(this).data('scroll') == data_scroll;
      })
      .find('img')
      .toArray();
    right_img = $('.scroll_right')
      .filter(function (i) {
        return $(this).data('scroll') == data_scroll;
      })
      .find('img')
      .toArray();

    if ($(tables[t]).find('.scroll_col').first().hasClass('scroll_hide')) {
      $('.scroll_left')
        .filter(function (i) {
          return $(this).data('scroll') == data_scroll;
        })
        .removeClass('faded');
      if (left_img) $(left_img).attr('src', img_url + 'left_red.png');
    } else {
      $('.scroll_left')
        .filter(function (i) {
          return $(this).data('scroll') == data_scroll;
        })
        .addClass('faded');
      if (left_img) $(left_img).attr('src', img_url + 'left-dimmed.png');
    }

    if ($(tables[t]).find('.scroll_col').last().hasClass('scroll_hide')) {
      $('.scroll_right')
        .filter(function (i) {
          return $(this).data('scroll') == data_scroll;
        })
        .removeClass('faded');
      if (right_img) $(right_img).attr('src', img_url + 'right_red.png');
    } else {
      $('.scroll_right')
        .filter(function (i) {
          return $(this).data('scroll') == data_scroll;
        })
        .addClass('faded');
      if (right_img) $(right_img).attr('src', img_url + 'right-dimmed.png');
    }
  }
}

function addTimePeriodButtons(input) {
  setTimeout(function () {
    var buttonPane = $(input).datepicker('widget').find('.ui-datepicker-buttonpane');

    var btns = [
      { text: '1M', value: '-1m' },
      { text: '3M', value: '-3m' },
      { text: '6M', value: '-6m' },
      { text: '1Y', value: '-1y' },
      { text: '2Y', value: '-2y' },
      { text: '5Y', value: '-5y' },
    ];

    $('.ui-datepicker-close').hide();

    btns.forEach(function (btnOptions) {
      var btn = $(
        '<button class="ui-state-default ui-priority-secondary ui-corner-all" type="button">' +
          btnOptions.text +
          '</button>',
      );
      btn.on('click', function () {
        // console.log($(input));
        $(input).datepicker('setDate', btnOptions.value);
        $(input).datepicker('hide');
        $(input).trigger('change');
        $(':focus').blur();
      });
      btn.appendTo(buttonPane);
    });
  }, 1);
}

function popup_guide() {
  var loading =
    '<p class="ac loading-animation large"><img src="' +
    window.img_url +
    'loadingAnimation.gif" alt="Loading..." /></p>';
  var link = $(this).data('guide');
  var parent_header = $(this).parent('th');
  parent_header.addClass('noSort');

  dialog_show(
    'value_input',
    400,
    loading,
    '',
    function (e) {
      var dialog = $(e.target);

      bb_get(
        site_url + 'ajax/get_dialog_content/',
        {
          link: link,
        },
        function (json) {
          if (dialog.parent().hasClass(link + '-guide')) {
            if (json.notification === undefined) {
              $('.' + link + '-guide .ui-dialog-title')[0].innerText = json.title;
              dialog.empty().append(json.content);
              dialog.closest('.ui-dialog-content').scrollTop = 0;
              $('.ui-dialog').focus();
            } else {
              dialog.empty().append(json.notification);
            }
          }
          parent_header.removeClass('noSort');
        },
      );
    }.bind(this),
    false,
    'auto',
    '',
    link + '-guide',
  );
}

$(function () {
  /* Object for Chart Printing - when a chart is present and the user 'prints' this will reflow the chart so that it's the right size,
   * then reflow it back for the browser again. Note chart height is 400px;
   */
  (function () {
    var reflowAllCharts = function () {
      // Reflow all charts on the page with .chart_container
      $('.chart_container').each(function () {
        var chart = $(this).highcharts();
        if (typeof chart != 'undefined') {
          if ($(chart.renderTo).is(':visible')) {
            chart.reflow();
            // chart.setSize(chart.width, 400, true);
          }
        }
      });
    };
    var beforePrint = function () {
      // console.log('Functionality to run before printing.');
      // Make the charts a size that doesn't conflict with the page.
      reflowAllCharts();
    };
    var afterPrint = function () {
      // console.log('Functionality to run after printing');
      // Return the charts to their original size.
      reflowAllCharts();
    };

    if (window.matchMedia) {
      var mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener(function (mql) {
        if (mql.matches) {
          beforePrint();
        } else {
          afterPrint();
        }
      });
    }

    window.onbeforeprint = beforePrint;
    window.onafterprint = afterPrint;
  })();

  $('body.tcopy .cba').on('contextmenu', function () {
    bb_post(
      site_url + 'ajax/capture_broker_action/',
      {
        element: $(this).prop('baseURI'),
        purpose: $(this).data('purpose'),
      },
      function () {
        // Do nothing
      },
    );
  });

  $('body.tcopy').on('keydown', function (event) {
    if (event.which === 83 && (event.ctrlKey || event.metaKey)) {
      bb_post(
        site_url + 'ajax/capture_broker_action/',
        {
          element: document,
          purpose: 'User engaged save dialog',
        },
        function () {
          // Do nothing
        },
      );
    }
  });

  $('#register.section').on('click', '.form_reveal', function () {
    var e = $(this);
    var d = e.data('reason');
    var p = e.parents('.form_reveal_buttons');
    p.slideUp(0);
    var f = p.next();
    f.slideDown(600).find('.form_conceal').slideDown(0);
    f.find('#reg_reason').val(d);
    return false;
  });

  $('#register.section').on('click', '.report_form_reveal', function () {
    var e = $(this);
    var d = e.data('reason');
    var p = e.parents('.form_reveal_buttons');
    p.slideUp(0);
    var f = p.next().next();
    f.slideDown(600).find('.request_form_conceal').slideDown(0);
    f.find('#request_download_reg_reason').val(d);
    return false;
  });

  $('#register.section').on('click', '.form_conceal', function () {
    var e = $(this);
    e.slideUp(0);
    var p = e.parents('.main_register_form');
    p.slideUp(600).siblings('.form_reveal_buttons').slideDown(300);
  });

  $('#register.section').on('click', '.request_form_conceal', function () {
    var e = $(this);
    e.slideUp(0);
    var p = e.parents('.request_report_form');
    p.slideUp(600).siblings('.form_reveal_buttons').slideDown(300);
  });

  $('#documents_results').on('change', '.documents_category', function () {
    var id = $(this).parents('tr').find('a').data('id');
    var cat = $(this).val();

    VV.Http.post(
      site_url + 'ajax/change_document_category/',
      {
        id: id,
        category: cat,
      },
      function (json) {
        if (typeof json.notification !== 'undefined') {
          alert(json.notification);
        }

        if (typeof json.category != 'undefined' && typeof json.id != 'undefined') {
          var line = $('#documents_results')
            .find('a')
            .filter(function (i) {
              return $(this).data('id') == json.id;
            });

          var select = line.parents('tr').find('.documents_category');

          if (typeof select != 'undefined') {
            $(select).val(json.category);
          }
        } else {
          alert('Something went wrong. Please try again or contact VV for more information.');
        }
      },
    );
  });

  $('#documents_results').on('click', '#documents_remove', function () {
    // Valuation/Company documents removal. SS.
    if (confirm('Are you sure you want to remove this file?')) {
      var id = $(this).data('id');
      bb_get(
        site_url + 'ajax/remove_document/',
        {
          id: id,
        },
        function (json) {
          if (typeof json !== 'undefined') {
            if (typeof json.removed !== 'undefined') {
              $('#documents_results')
                .find('a')
                .filter(function (i) {
                  return $(this).data('id') == json.removed;
                })
                .parent()
                .parent()
                .remove();
              $('#document_count').empty().html($('#documents_results > *').length);
            }
            if (typeof json.notification !== 'undefined') alert(json.notification);
          }
        },
      );
      return false;
    }
    return false;
  });

  $('#responsive_nav_trigger, #responsive_nav_con a[onClick]').on(
    'touchstart click',
    function (evt) {
      /* if($(this).prop('id') == 'responsive_nav_trigger'){ */
      evt.preventDefault();
      /* } */

      var body = $('body');
      var page = $('#everythingbarthefooter, #footer_con, .vv_at_frame');
      var menu = $('#responsive_nav_con');

      /* Cross browser support for CSS "transition end" event */
      transitionEnd = 'transitionend webkitTransitionEnd otransitionend MSTransitionEnd';
      body.addClass('rm_anim');

      /***
       * Determine the direction of the animation and
       * add the correct direction class depending
       * on whether the menu was already visible.
       */

      if (body.hasClass('rm_vis')) {
        body.addClass('right');
      } else {
        body.addClass('left');
      }
      page.on(transitionEnd, function () {
        body.removeClass('rm_anim left right').toggleClass('rm_vis');
        page.off(transitionEnd);
      });
    },
  );

  $('#news_poplink').click(function () {
    dialog_show('latestNews', 620, false, false);
    return false;
  });

  $('body').on('click', 'a.nolink', function () {
    return false;
  });

  $('body').on('click', 'a.popup, a.phone_number', function () {
    window.open($(this).attr('href'));
    return false;
  });

  $('body').on('click', '.pagination a', function () {
    if ($(this).hasClass('page_active') || $(this).hasClass('faded')) return false;

    var table_name = $(this).closest('.pagination').data('table');
    $('#table_' + table_name)
      .find('tr.more_joint_venture')
      .remove();

    paging(table_name, $(this).data('page'));
    return false;
  });

  $('body').on('change', '.pagination .limiter select', function () {
    var table_name = $(this).closest('.pagination').data('table');
    $('#table_' + table_name)
      .find('tr.more_joint_venture')
      .remove();

    paging_limit(table_name, $(this).val(), $(this).data('limit'));
  });

  $('#feedback_cont form').submit(function () {
    return sendFeedback();
  });

  $('#feedback_cont input:submit').click(function () {
    return sendFeedback();
  });

  feedback_default = $('#feedback_cont textarea').val();
  $('#feedback_cont textarea')
    .focus(function () {
      if ($(this).val() == feedback_default) $(this).val('');
    })
    .blur(function () {
      if ($(this).val() == '') $(this).val(feedback_default);
    });

  $('body').on('click', '#quick_links a', function () {
    var id = $(this)
      .attr('id')
      .substring(0, $(this).attr('id').length - 5);
    if (quicktabbed[id] == false) pagify(id);
  });

  $(':input')
    .focus(function () {
      if (this.title !== '' && this.title == this.value) this.value = '';
    })
    .blur(function () {
      if (this.title !== '' && this.value == '') this.value = this.title;
    });

  $('.tab').hover(function () {
    show_tab($(this).attr('id'));
  });

  $('body').on('submit', '#login_form', function () {
    var rtn = true;
    if ($('#login_username').length > 0) {
      if ($('#login_username').val() == 'Email...' || $('#login_username').val() == '') {
        $('#login_error_username').text('Please supply your email address').slideDown(200);
        rtn = false;
      } else {
        $('#login_error_username').empty().slideUp(200);
      }
      if ($('#login_password').val() == 'Password...' || $('#login_password').val() == '') {
        $('#login_error_password').text('Please supply your password').slideDown(200);
        rtn = false;
      } else {
        $('#login_error_password').empty().slideUp(200);
      }
    }
    return rtn;
  });

  $('body')
    .off('.sorting_top th[data-field], .sorting_top th[data-field] a')
    .on(
      'click',
      '.sorting_top th[data-field], .sorting_top th[data-field] a:not(.more_info)',
      function () {
        if ($(this).hasClass('noSort')) {
          return false;
        }

        if ($(this).is('a[href]') && $(this).attr('href') != '#') {
          return true;
        }

        var el = $(this);

        if ($(this).is('a')) {
          el = $(this).closest('th');
          $(this).blur();
        }

        var field = $(el).data('field');
        var table = $(el).closest('table').data('table');

        $('table')
          .filter(function (i) {
            return $(this).data('table') == table;
          })
          .find('.sorting_top th')
          .removeClass('sortAsc')
          .removeClass('sortDesc');

        if (sorting[table] === undefined) {
          return false;
        }

        if (field == sorting[table]) {
          sorting[table] += ' DESC';

          $('table')
            .filter(function (i) {
              return $(this).data('table') == table;
            })
            .find('.sorting_top th')
            .filter(function (i) {
              return $(this).data('field') == field;
            })
            .addClass('sortDesc');
        } else if (sorting[table].indexOf(field) > -1) {
          sorting[table] = field;

          $('table')
            .filter(function (i) {
              return $(this).data('table') == table;
            })
            .find('.sorting_top th')
            .filter(function (i) {
              return $(this).data('field') == field;
            })
            .addClass('sortAsc');
        } else if ($(el).hasClass('desc')) {
          sorting[table] = field + ' DESC';

          $('table')
            .filter(function (i) {
              return $(this).data('table') == table;
            })
            .find('.sorting_top th')
            .filter(function (i) {
              return $(this).data('field') == field;
            })
            .addClass('sortDesc');
        } else {
          sorting[table] = field;

          $('table')
            .filter(function (i) {
              return $(this).data('table') == table;
            })
            .find('.sorting_top th')
            .filter(function (i) {
              return $(this).data('field') == field;
            })
            .addClass('sortAsc');
        }

        pagify(table);
        resizeTab();

        return false;
      },
    );

  search_bar_right = $('#search_close').html();

  $('body').on('click', '.scroll_left, .scroll_left a', function () {
    return scroll('left', this);
  });

  $('body').on('click', '.scroll_right, .scroll_right a', function () {
    return scroll('right', this);
  });

  scroll_refresh();

  $('#quick_search').on({
    click: function () {
      clearTimeout(quick_timeout);
      stop_quick_timeout = true;
    },
    focus: function () {
      clearTimeout(quick_timeout);
      stop_quick_timeout = true;
      var $this = $(this);
      $(this).removeClass('usemegrey');
      $($this).select();
      $($this).mouseup(function () {
        $($this).unbind('mouseup');
        return false;
      });
    },
    blur: function () {
      $(this).addClass('usemegrey');
      if (typeof bill_session_id === 'undefined') {
        bill_session_id = false;
      }
    },
    keyup: function (e) {
      quick_go = e.keyCode == 13 || e.which == 13;
      quick_done = false;
      last_quick_search = $('#quick_search').val();

      if (quick_search_timeout !== null) {
        clearTimeout(quick_search_timeout);
      }

      if (last_quick_search) {
        var wait_length = 500;
        if (last_quick_search.length == 1) {
          wait_length = 3000;
        } else if (last_quick_search.length == 2) {
          wait_length = 2000;
        } else if (last_quick_search.length == 3) {
          wait_length = 1000;
        }

        if (quick_go) wait_length = 0; // If enter pressed, execute immediately but yield for JS to catch up.

        quick_search_timeout = setTimeout(function () {
          get_quick_search(last_quick_search);
        }, wait_length);
      }
      return true;
    },
  });

  $('body').on('click', '.search_bar_close', function () {
    quickResultsHide();
    return false;
  });

  $(document)
    .click(function () {
      if (!stop_quick_timeout) quick_timeout = setTimeout('quickResultsHide()', 250);
      stop_quick_timeout = false;
    })
    .keydown(function (e) {
      if (e.keyCode == 17) ctrlDown = true;
    })
    .keyup(function (e) {
      if (e.keyCode == 17) ctrlDown = false;
    });

  $('body').on('click', '.ticker_con, .new_header #quick_search', function () {
    clearTimeout(quick_timeout);
    if (last_quick_search != '') quickResultsShow();
    stop_quick_timeout = true;
  });

  $('body').on('click', '.search_con', function () {
    clearTimeout(quick_timeout);
    stop_quick_timeout = true;
  });

  var activetabs = $('.tabstrip a:not(.more_info).active, .tabstrip div.tablinks').toArray();

  for (var i in activetabs) {
    if (activetabs.hasOwnProperty(i)) {
      var parent_id,
        $atab = $(activetabs[i]);

      if ($atab.closest('.tabstrip').data('tabs')) {
        parent_id = $atab.closest('.tabstrip').data('tabs');
      } else {
        parent_id = $atab.parent().attr('id').substring(0, $atab.parent().attr('id').indexOf('_'));
      }

      $atab.addClass('default');

      if ($atab.data('tab')) {
        activetab[parent_id] = $atab.data('tab');
      } else {
        activetab[parent_id] = $atab.attr('id').substring(0, $atab.attr('id').indexOf('_'));
      }
    }
  }

  if (typeof $.history !== 'undefined') {
    $.history.init(changeTab);
  }

  var tablinks = $('.tabstrip a:not(.more_info), .tabstrip div.tablinks').toArray();

  for (var i in tablinks) {
    if ($(tablinks[i]).attr('href')) continue;

    if ($(tablinks[i]).closest('.tabstrip').data('tabs')) {
      var pid = $(tablinks[i]).closest('.tabstrip').data('tabs');
    } else {
      var pid = $(tablinks[i])
        .parent()
        .attr('id')
        .substring(0, $(tablinks[i]).parent().attr('id').indexOf('_'));
    }

    var tl = '#';

    for (var t in activetab) {
      if (tl != '#') {
        tl += '/';
      }

      if (t != pid) {
        tl += activetab[t];
      } else if ($(tablinks[i]).data('tab')) {
        tl += $(tablinks[i]).data('tab');
      } else {
        tl += $(tablinks[i]).attr('id').substring(0, $(tablinks[i]).attr('id').indexOf('_'));
      }
    }

    $(tablinks[i]).attr('href', tl);
    $(tablinks[i]).blur();
  }

  $('body').on('click', '.tabstrip a:not(.more_info), .tabstrip div.tablinks', function () {
    var href = $(this).attr('href');

    if (href && href.charAt(0) != '#') {
      return true;
    }

    if (href) {
      return changeTab(href.slice(1));
    }

    return false;
  });

  resizeTab();

  var tabstrip = $('.tabstrip').toArray();

  for (var i in tabstrip) {
    if ($(tabstrip[i]).data('tabs')) {
      var container = $('.tabcontainer').filter(function (i) {
        return $(this).data('tabs') == $(tabstrip[i]).data('tabs');
      });
    } else {
      var container = $(
        '#' + $(tabstrip[i]).attr('id').substr(0, $(tabstrip[i]).attr('id').indexOf('_')) + '_tabs',
      );
    }

    if ($(tabstrip[i]).children('a, div').length == 0) {
      continue;
    }

    if ($(tabstrip[i]).children('a.active, div.tablinks.active').length == 0) {
      changeTab($(tabstrip[i]).children('a, div.tablinks').first());
    } else if ($(container).children('a.tabactive').length == 0) {
      changeTab($(tabstrip[i]).children('a.active, div.tablinks.active').first());
    }
  }

  $('body').on('click', '.buy_credits_button', function () {
    location.href = site_url + 'buy_credits/';
    return false;
  });

  $('#valuation_notice').on('change', ':checkbox', function () {
    var checks = $('#valuation_notice :enabled:checked').toArray();
    var upto = '';
    var cost = 0;

    if (checks.length > parseInt($(this).closest('form').data('credits'))) return false;

    if (checks.length == 0) {
      $('#value_demo').prop('checked', false);
      $('#value_submit').prop('disabled', 'disabled');
      upto = 'up to ';
      checks = $('#valuation_notice :checkbox:enabled').toArray();
    } else {
      $('#value_demo').prop('checked', true);
      $('#value_submit').prop('disabled', null);
    }

    for (i in checks) {
      cost += parseInt($(checks[i]).val());
    }

    $('#value_submit').val('Use ' + upto + cost + ' credit' + (cost == 1 ? '' : 's'));

    return true;
  });

  $('.header_con').on('click', '#login_link, #notification_login', function () {
    if (typeof getcookie !== 'undefined') {
      if (getcookie('__cookies_Essential') == null || getcookie('__cookies_Essential') != 1)
        $('#session_cookies_required').show();
      else $('#session_cookies_required').hide();
    }

    if ($('#login_cont').css('display') === 'block') {
      $('#login_cont').slideUp(200, function () {
        $('#login_link').removeClass('hover');
        $('.ticker_right').show();
      });
    } else {
      $('#feedback_cont').slideUp(200, function () {
        $('#feedback_link').removeClass('hover');
        $('#login_link').addClass('hover');
        $('#login_cont').slideDown(200);
        $('.ticker_right').show();
      });

      $('#login_cont #username').focus();
    }
    return false;
  });

  $('#feedback_link').on('click', function () {
    if ($('#feedback_cont').css('display') === 'block') {
      $('#feedback_cont').slideUp(200, function () {
        $('#feedback_link').removeClass('hover');
        $('.ticker_right').show();
      });
    } else {
      $('#login_cont').slideUp(200, function () {
        $('#login_link').removeClass('hover');
        $('.ticker_right').show();
        $('#feedback_link').addClass('hover');
        $('#feedback_cont').slideDown(200);
      });

      if ($.browser.msie && parseFloat($.browser.version) < 8) $('.ticker_right').hide();
      $('#feedback_cont textarea').focus();
    }
    return false;
  });

  if (typeof skip_tradewinds == 'undefined' || !skip_tradewinds) {
    bb_get(site_url + 'tradewinds.xml', function (xml) {
      tradewinds_news = [];
      var tradewinds_more_info = '';
      var items = $(xml).find('channel > item').toArray();
      // 4.32e+8 is 5 days in milliseconds.
      var fiveDaysAgo = new Date(new Date() - 432000000);
      for (var i in items) {
        if (items.hasOwnProperty(i)) {
          var thisDate = new Date($(items[i]).find('dcterms\\:modified').text());

          var pubItemTitle = $(items[i]).find('title').text();
          var pubItemDate = $(items[i]).find('pubDate').text();
          var pubItemLink = $(items[i]).find('link').text();
          var pubItemDesc = $(items[i]).find('description').text();

          // If the news story is more than 5 days old then don't add it to the news feed.
          if (thisDate >= fiveDaysAgo) {
            tradewinds_news.push({
              title: pubItemTitle,
              link: pubItemLink,
              date: pubItemDate,
            });

            tradewinds_more_info +=
              '<div class="white_bg vv-p-10 vv-mt-10 br-3">' +
              '<a href="' +
              pubItemLink +
              '">' +
              pubItemTitle +
              '</a>' +
              '<p class="vv-mt-5 vv-mb-0 text-sm">' +
              pubItemDesc +
              '</p>' +
              '<p class="vv-mt-5 vv-mb-0 usemegrey text-xs">' +
              pubItemDate.substring(0, pubItemDate.length - 9) +
              '</p>' +
              '</div>';
          }
        }
      }

      reset_tradewinds_more_info_panel();

      $('#tradewinds_more_info_blocks').empty().html(tradewinds_more_info);

      tradewinds_timer = setInterval(tradewinds_tick, 7000);
      tradewinds_tick();
    });

    $('#news_ticker').on({
      mouseenter: function () {
        clearInterval(tradewinds_timer);
      },
      mouseleave: function () {
        tradewinds_timer = setInterval(tradewinds_tick, 7000);
      },
      click: function (e) {
        if (typeof gaTagManager !== 'undefined') {
          gaTagManager.push({
            event: 'gtm.linkClick',
            'gtm.element': e.target.href,
            'gtm.elementUrl': e.target.href,
            'gtm.elementTarget': '_BLANK',
          });
        }
      },
    });

    $('#tradewinds_more_info').on('click', function (event) {
      event.stopPropagation();
      $('.tradewinds_more_info_panel').slideToggle(function () {
        if ($(this).is(':hidden')) {
          $('#tradewinds_more_info').find('.caret').removeClass('caret-close');
        } else {
          $('#tradewinds_more_info').find('.caret').addClass('caret-close');
        }
      });
    });

    $(window).on('resize', function () {
      reset_tradewinds_more_info_panel();
    });

    function reset_tradewinds_more_info_panel() {
      $('.tradewinds_more_info_inner').css(
        'height',
        window.innerHeight - ($('.header_con').height() + 45),
      );
    }
  }

  $('body').on('click', 'a.toggle', function () {
    var name = $(this).data('toggle');

    if (
      $('div.toggle').filter(function (i) {
        return $(this).data('toggle') == name;
      }).length > 0
    )
      var div = $('div.toggle').filter(function (i) {
        return $(this).data('toggle') == name;
      });
    else if (
      $('span.toggle').filter(function (i) {
        return $(this).data('toggle') == name;
      }).length > 0
    )
      var div = $('span.toggle').filter(function (i) {
        return $(this).data('toggle') == name;
      });
    else return false;

    if ($(this).hasClass('toggle_hidden')) {
      $(div).slideDown();
      $(this).empty().text('(hide)').removeClass('toggle_hidden');
    } else {
      $(div).slideUp();
      $(this).empty().text('(show)').addClass('toggle_hidden');
    }

    return false;
  });

  $('body').on('click', '.popup_guide', popup_guide);

  $('body').on('change', ':checkbox.row_check', function () {
    var row = $(this).closest('tr');
    var table = $(this).closest('table').data('table');

    if ($(row).parent().is('table')) {
      var all = $(row).parent().find('.row_check').toArray();
    } else if ($(row).parent().is('tbody')) {
      var all = $(row).parent().find('.row_check').toArray();
      $(row)
        .closest('table')
        .find('thead .row_check')
        .prop('checked', $(all).length == $(all).filter(':checked').length);
    } else if ($(row).parent().is('thead')) {
      var all = $(row).closest('table').find('tbody .row_check').toArray();
      $(all).prop('checked', $(this).prop('checked'));
    }

    if ($(all).filter(':checked').length == 0) {
      $('select.row_check')
        .filter(function (i) {
          return $(this).data('table') == table;
        })
        .prop('disabled', 'disabled');
      $('select.row_check_reverse')
        .filter(function (i) {
          return $(this).data('table') == table;
        })
        .prop('disabled', null);
      $('.row_check:not(:input)')
        .filter(function (i) {
          return $(this).data('table') == table;
        })
        .hide();
      $('.row_check_reverse:not(:input)')
        .filter(function (i) {
          return $(this).data('table') == table;
        })
        .show();
    } else {
      $('select.row_check')
        .filter(function (i) {
          return $(this).data('table') == table;
        })
        .prop('disabled', null);
      $('select.row_check_reverse')
        .filter(function (i) {
          return $(this).data('table') == table;
        })
        .prop('disabled', 'disabled');
      $('.row_check:not(:input)')
        .filter(function (i) {
          return $(this).data('table') == table;
        })
        .show();
      $('.row_check_reverse:not(:input)')
        .filter(function (i) {
          return $(this).data('table') == table;
        })
        .hide();
    }
  });
});

function multiCreditConfirm(credits) {
  if (credits > 1) {
    return confirm(
      'Multiple Credits Warning\r\nYou are about to use ' +
        credits +
        ' credits. Please click OK to confirm.',
    );
  }
  return true;
}

/**
 * @param action
 * @param dialog_width
 *
 */
function export_csv_option_dialog_show(action, dialog_width) {
  if (action.indexOf('onClick') >= 0) {
    action = action.substring(0, action.length - 1);
  }

  $html = '<span>';
  $html +=
    '<p class="my-4">By exporting these values you agree to VesselsValue\'s <a href="' +
    site_url +
    'terms/" target="_blank">Terms &amp; Conditions</a></p>';
  $html += '<p>';
  $html +=
    '<select id="export_as" name="export_as" class="select-rounded-style pr-6" style="padding-right: 1.5rem">';
  $html += '<option value="csv">Download as .csv</option>';
  $html += '<option value="xlsx">Download as .xlsx</option>';
  $html += '</select>';

  $html += '<a id="dialog_accept_terms" class="button lg_btn vv-mx-10" ' + action + '>I agree</a>';
  $html += '</p></span>';

  dialog_show(
    false,
    300,
    $html,
    'Export Confirmation',
    function (event) {
      $(event.target).on('change', '#export_as', function (event) {
        var modal = $(event.target).closest('.ui-dialog-content');
        var regex = new RegExp('export_type=([^&]*)');

        var acceptTerms = modal.find('#dialog_accept_terms');
        var fileType = modal.find('#export_as option:selected').val() || 'csv';
        var acceptTermsHref = acceptTerms.prop('href');

        acceptTermsHref = acceptTermsHref.replace(regex, 'export_type=' + fileType);
        acceptTerms.prop('href', acceptTermsHref);
      });
    },
    false,
    false,
  );
}

/**
 * Removed the Deprecated tab as it still has a legitimate use for single type exports.
 * @see valuation.js:5605 (trade_compare_ship_trigger)
 *
 * @param {string} action
 * @param {number} dialog_width
 */
function export_dialog_show(action, dialog_width) {
  if (action.indexOf('onClick') >= 0) {
    action = action.substring(0, action.length - 1);
  }

  $html = '<span>';
  $html +=
    '<p class="my-4">By exporting these values you agree to VesselsValue\'s <a href="' +
    site_url +
    'terms/" target="_blank">Terms &amp; Conditions</a></p>';
  $html += '<p class="my-4">';

  $html += '<a id="dialog_accept_terms" class="button lg_btn vv-mx-10" ' + action + '>I agree</a>';
  $html += '</p></span>';

  dialog_show(
    false,
    300,
    $html,
    'Export Confirmation',
    function (e) {
      $(e.target)
        .find('#dialog_accept_terms')
        .on('click', function (event) {
          $(event.target).closest('.ui-dialog-content').dialog('close');
        });
    },
    false,
    false,
  );
}

/**
 * @deprecated
 */
function profile_dialog_show(action, dialog_width, name, job_title, description, img) {
  $html = '<div class="container about_cn cf">';
  $html += '<div class="two_fifths sm_tablet_full_width mobile_full_width">';
  $html += '<div class="profile_popup_sp">';
  $html += '<img src="' + img + '"/>';
  $html += '</div>';
  $html += '</div>';
  $html += '<div class="three_fifths sm_tablet_full_width mobile_full_width">';
  $html += '<div class="profile_popup_sp">';
  $html +=
    '<h3 class="black"><strong>' +
    name +
    '</strong><div class="useme2grey">' +
    job_title +
    '</div></h3>';
  $html += '<hr class="profile_popup lightgrey">';
  $html += '<p class="useme2grey">' + description + '</p>';
  $html += '</div>';
  $html += '</div>';
  $html += '</div>';

  dialog_show(
    false,
    dialog_width,
    $html,
    name,
    false,
    false,
    false,
    false,
    'dgrey_dialog_titlebar',
  );
}

function populateDialog(popup, title, content, noPadding) {
  popup.addProperties({
    title: title,
  });

  popup.draw();
  popup.replaceBody(content);

  var popupContainer = $('#popup_container');
  if (noPadding) {
    popupContainer.addClass('p-0');
  } else {
    popupContainer.removeClass('p-0');
  }
}

// FixMe: Make this better, dialog options should be an extendable object,
// FixMe: could get rid of MOST of these arguments!
function dialog_show(
  action,
  width,
  noAjax,
  noAjaxTitle,
  onComplete,
  useData,
  height,
  noPadding,
  dialogClass,
  closeOnEscKey,
  options,
) {
  var popup = Object.create($.extend(true, {}, new VV.Popup()));
  var returnEvent = undefined;

  popup.addProperties(
    $.extend(
      {
        height: height || 'auto',
        width: width || 500,
        open: function (e) {
          returnEvent = e;
        },
        dialogClass: dialogClass || '',
        closeOnEscape: closeOnEscKey || true,
        maxHeight: Math.min(window.innerHeight - 210, 800),
        position: {
          my: 'top middle',
          at: 'top+210',
          of: $(window),
          collision: 'fit',
        },
      },
      options,
    ),
  );

  var title = noAjaxTitle;
  var content = noAjax;

  if (noAjax === false && noAjaxTitle === false) {
    bb_get(
      site_url + 'ajax/get_dialog_content/',
      {
        link: action,
        data: useData || false,
      },
      function (res) {
        title = res.title === undefined ? 'Explanation coming soon' : res.title;
        content = res.content;

        populateDialog(popup, title, content, noPadding);

        if (typeof onComplete === 'function') {
          onComplete(returnEvent);
        }
      },
    );
  } else {
    populateDialog(popup, title, content, noPadding);

    if (typeof onComplete === 'function') {
      onComplete(returnEvent);
    }
  }
}

function formInputPreventTrigger(form) {
  if (typeof document.getElementById(form) == 'undefined') {
    $(form).on('keypress', 'input:not(submit)', function (e) {
      return e.keyCode != 13;
    });
  }
}

function button_flash(el, count) {
  $(el).toggleClass('dimmed');
  if (count > 0.5) {
    setTimeout(function () {
      button_flash(el, count - 0.5);
    }, 150);
  }
}

function str_repeat(str, count) {
  var rtn = '';
  for (var i = 0; i < count; i++) rtn += str;
  return rtn;
}

function lengthOf(obj) {
  var l = 0;
  for (var o in obj) if (obj.hasOwnProperty(o)) l++;
  return l;
}

function reverse(obj) {
  var rtn = {};
  var i = lengthOf(obj);
  for (var o in obj) {
    i--;
    rtn[i] = obj[o];
  }
  return rtn;
}

function mod(a, n, z) {
  if (typeof z == 'undefined') z = 0;
  if (z > n) {
    var t = z;
    z = n;
    n = t;
  }
  var r = (a + n - z) % (n - z);
  if (r < z) r += n - z;
  if (r > n) r -= n - z;
  return r;
}

function shuttleSort(arr) {
  var begin = -1;
  var end = arr.length;
  var swapped = true;
  var temp;
  while (swapped) {
    swapped = false;
    begin += 1;
    for (var i = begin; i <= end; i++) {
      if (arr[i] > arr[i + 1]) {
        temp = arr[i];
        arr[i] = arr[i + 1];
        arr[i + 1] = temp;
        swapped = true;
      }
    }
    if (swapped) {
      swapped = false;
      for (var i = end; i >= begin; i--) {
        if (arr[i] > arr[i + 1]) {
          temp = arr[i];
          arr[i] = arr[i + 1];
          arr[i + 1] = temp;
          swapped = true;
        }
      }
    }
  }
  return arr;
}

function dot_animate_start(sel) {
  var sels = $(sel).toArray();
  for (var i in sels) $(sels[i]).addClass('dot_animate');

  if ($('.dot_animate').length > 0 && dots_interval === false) {
    dots_interval = setInterval(function () {
      dots_tick++;
      if (dots_tick == dots_ticks) dots_tick = 0;
      var el = $('.dot_animate').toArray();
      for (var i in el) {
        dots = 0;
        for (var i = 1; i <= dots_count; i++) {
          if (dots_tick == i) dots = Math.pow(2, dots_count) - Math.pow(2, dots_count - i);
          else if (dots_tick + i == dots_count * 2) dots = Math.pow(2, i) - 1;
          if (dots > 0) break;
        }
        dots_text = '';
        for (var i = dots_count - 1; i >= 0; i--) {
          if ((dots & Math.pow(2, i)) == Math.pow(2, i)) dots_text += '<span>.</span>';
          else dots_text += '<span class="hide_dot">.</span>';
        }
        $(el[i]).empty().html(dots_text);
      }
    }, dots_step);
  }
}

function dot_animate_stop(sel) {
  var selects = $(sel).toArray();
  for (var i in selects) $(selects[i]).removeClass('dot_animate');

  if ($('.dot_animate').length == 0 && dots_interval !== false) {
    clearInterval(dots_interval);
    dots_interval = false;
    dots_tick = 0;
  }
}

function colour_sorted_column(table) {
  $('table')
    .filter(function (i) {
      return $(this).data('table') == table;
    })
    .find('.sorting_top th')
    .removeClass('sortAsc')
    .removeClass('sortDesc');

  $('table')
    .filter(function (i) {
      return $(this).data('table') == table;
    })
    .find('tbody td')
    .removeClass('sorted');

  if (sorting[table].indexOf(' DESC') === -1) {
    $('table')
      .filter(function (i) {
        return $(this).data('table') == table;
      })
      .find('.sorting_top th')
      .filter(function (i) {
        return $(this).data('field') == sorting[table];
      })
      .addClass('sortAsc');
    $('table')
      .filter(function (i) {
        return $(this).data('table') == table;
      })
      .find('tbody td')
      .filter(function (i) {
        return $(this).data('field') == sorting[table];
      })
      .addClass('sorted');

    return;
  }

  $('table')
    .filter(function (i) {
      return $(this).data('table') == table;
    })
    .find('.sorting_top th')
    .filter(function (i) {
      return $(this).data('field') == sorting[table].slice(0, sorting[table].indexOf(' '));
    })
    .addClass('sortDesc');

  $('table')
    .filter(function (i) {
      return $(this).data('table') == table;
    })
    .find('tbody td')
    .filter(function (i) {
      return $(this).data('field') == sorting[table].slice(0, sorting[table].indexOf(' '));
    })
    .addClass('sorted');
}

function special_lower_case(string) {
  var rtn = '';
  var words = string.split(' ');
  for (var w in words) {
    if (words[w] == words[w].toUpperCase()) rtn += ' ' + words[w];
    else rtn += ' ' + words[w].toLowerCase();
  }
  return rtn;
}

function escape(value) {
  return value.toString().replace(/[#%;&,\.\+\*~':"!\^\$\[\]\(\)=>|\/\\]/g, '\\$&');
}

function formatBytes(bytes, decimals) {
  if (bytes < 2) return bytes + ' Bytes';
  var k = 1000;
  var dm = decimals + 1 || 3;
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
    ' ' +
    (sizes[i] == 'undefined' ? '>YB' : sizes[i])
  );
}

function add_to_form(form, obj, prefix) {
  for (var i in obj) {
    var name = prefix ? prefix + '[' + i + ']' : i;
    if (typeof obj[i] == 'object') add_to_form(form, obj[i], name);
    else $(form).append('<input name="' + name + '" value="' + obj[i] + '" type="hidden" />');
  }
}

function outputMultiDCF(type_id) {
  var options = {
    ids: portfolio_id,
    subject: 'portfolio',
    selected: dcf_assumptions,
  };

  if (typeof type_id !== 'undefined') {
    options['type_id'] = type_id;
  }

  var button = $('#calculateMultiDCF');

  if (!button.hasClass('dimmed')) {
    button
      .addClass('dimmed')
      .prepend(
        '<img class="fl" src="' +
          img_url +
          'loadingAnimation.gif" alt="loading" style="width: 20px; margin-right: 5px;" />',
      );
  }

  bb_post(site_url + 'ajax/get_dcf_value_group/', options, function (json) {
    var modified = false;
    var table = $('#table_dcf');
    var rows = $('#table_dcf').find('tr[data-id]');

    rows.each(function (x) {
      if (rows.hasOwnProperty(x)) {
        var row = $(rows[x]);
        var data_id = row.data('id');

        var options = json.assumptions[data_id];
        var assumption = row.find('td.assumption');

        if ($(options).length) {
          var selection =
            '<select class="w100 select-rounded-style"><option value="-1" ' +
            (typeof dcf_assumptions[data_id] == 'undefined' || dcf_assumptions[data_id] == -1
              ? 'selected="selected"'
              : '') +
            '>Default</option>';
          var selected = 'selected="selected"';

          for (var z in options) {
            if (options.hasOwnProperty(z)) {
              dcf_assumptions_store[x] = options;
              selection +=
                '<option value="' +
                z +
                '" ' +
                (dcf_assumptions[data_id] == z ? 'selected="selected"' : '') +
                '>' +
                options[z] +
                '</option>';
              if (dcf_assumptions[x] == z) {
                modified = true;
              }
            }
          }
          selection += '</select>';
          assumption.html(selection);
        } else {
          assumption.html('<span>Default</span>');
        }

        // Updated Values.
        var value = row.find('td[data-field="valuation_latest_dcf"]');
        var display =
          typeof json.dcf_values[data_id] === 'undefined'
            ? redact
            : parseFloat(json.dcf_values[data_id] / 1000000).toFixed(2);

        var a_class =
          typeof dcf_assumptions[data_id] != 'undefined' && dcf_assumptions[data_id] != -1
            ? 'dcf_adjusted'
            : '';
        // Write them.
        value.html('<span class="' + a_class + '">' + display + '</span>');
      }
    });

    if (json.vessels.length == 0) {
      table.append(
        '<tr><td colspan="' +
          count_cols(table) +
          '"><strong>There are no DCF valuable vessels in your portfolio.</strong></td></tr>',
      );
    }

    var total_vessels = json.vessels_total;
    var total_value =
      val_format((json.dcf_total / 1000000).toFixed(2), 2, 0, true, true) +
      (total_vessels < vessel_count ? '*' : '');
    var portfolio_dcf = $('#value_dcf_portfolio');
    var portfolio_vessels = $('#number_dcf_portfolio');

    portfolio_dcf.html(total_value);
    portfolio_vessels.html(total_vessels);
    if (modified) {
      portfolio_dcf.addClass('dcf_adjusted');
    } else {
      portfolio_dcf.removeClass('dcf_adjusted');
    }

    $('#calculateMultiDCF').removeClass('dimmed').find('img').remove();
  });
}

/**
 * This function will take the DCF dropdowns and set the selected ones in an array.
 * It will then check if any have changed, and if they have then call the DCF output again.
 */
function calculateMultiDCF() {
  if ($('#calculateMultiDCF').hasClass('dimmed')) {
    return;
  }
  var table = $('#table_dcf');
  var items = table.find('tr[data-id!=""]:not(#dcf_top)').toArray();
  var select;
  for (var x in items) {
    var selected = -1;
    if (items.hasOwnProperty(x) && typeof items[x] != 'undefined') {
      select = $(items[x]).find('td.assumption > select');
      if (select.length > 0) {
        selected = parseInt(select.val());
      }
      dcf_assumptions[$(items[x]).data('id')] = selected;
    }
  }
  outputMultiDCF();
}

function selectLatestDCF() {
  var table = $('#table_dcf');
  var items = table.find('tr[data-id!=""]:not(#dcf_top)').toArray();
  var select;
  for (var x in items) {
    if (items.hasOwnProperty(x) && typeof items[x] != 'undefined') {
      $(items[x]).find('td.assumption select option:last').prop('selected', 'selected');
    }
  }
}

function outputForecastPage(json, options) {
  if (typeof json === 'undefined') {
    return false;
  }

  options = typeof options === 'undefined' ? {} : options;
  var forecast_chart = (options.chart =
    typeof options.chart === 'undefined' ? $('#forecast_chart') : $(options.chart));
  var valuation_dates = (options.dates =
    typeof options.dates === 'undefined' ? $('#fcvod') : $(options.dates));
  var valuation_table = (options.table =
    typeof options.table === 'undefined'
      ? $('#forecast_table').find('.forecast_tbody')
      : $(options.table));
  var nb_sparkline = (options.nbspkln =
    typeof options.nbspkln === 'undefined' ? $('#nbspkln') : $(options.nbspkln));
  var type_sparkline = (options.tyspkln =
    typeof options.tyspkln === 'undefined' ? $('#tyspkln') : $(options.tyspkln));
  var fc_commentary = (options.comments =
    typeof options.comments === 'undefined' ? $('#forecast_commentary') : $(options.table));
  var fc_published = (options.published =
    typeof options.fcpbd === 'undefined' ? $('#fcpbd') : $(options.published));

  if (typeof forecast_chart !== 'undefined' && forecast_chart.length > 0) {
    graph_manager[forecast_chart] = new Highcharts.StockChart(json.chart);
    var dataMin = new Date(graph_manager[forecast_chart].axes[0].getExtremes().dataMin);
    var dataMax = new Date(graph_manager[forecast_chart].axes[0].getExtremes().dataMax);
    setTimeout(function () {
      $(
        'input.highcharts-range-selector',
        $('#' + graph_manager[forecast_chart].options.chart.renderTo),
      ).datepicker({
        changeMonth: true,
        changeYear: true,
        prevText: '&lt;',
        nextText: '&gt;',
        dateFormat: 'D dd M yy',
        minDate: dataMin,
        maxDate: dataMax,
        yearRange: dataMin.getFullYear() + ':' + dataMax.getFullYear(),
        onSelect: function (dateText) {
          this.onchange();
          this.onblur();
        },
      });
    }, 0);
  }

  // Header Values
  if (typeof json.curr !== 'undefined') {
    var fcvalh = '';
    var this_val;
    var dec_val = val_format(json.curr[0][2], 2),
      x;

    // this_val = (dec_val.split('.')[0] + '<span class="dec">.'+(dec_val.split('.')[1])+'</span>');
    // fcvalh += '<div class="one_fifth print_one_fifth tablet_one_third mobile_one_half valuation_amount market_colour">';
    // fcvalh += '<p class="valuation_undertitle">Forecast Value</p>';
    // fcvalh += '<p class="amount">'+this_val+(json.full ? '' : '*')+'</p>';
    // fcvalh += '<p class="subamount">'+json.curr[0][0]+'</p>';
    // fcvalh += '</div>';

    if (typeof json.fcvst !== 'undefined') {
      if (typeof json.fcvst[2] !== 'undefined' && json.fcvst[2] != null) {
        dec_val = val_format(json.fcvst[2][2], 2);
        this_val =
          dec_val.split('.')[0] + '<span class="dec">.' + dec_val.split('.')[1] + '</span>';
        fcvalh +=
          '<div class="one_fifth print_one_fifth tablet_one_third mobile_one_half valuation_amount market_colour">';
        fcvalh += '<p class="valuation_undertitle">+6 Months</p>';
        fcvalh += '<p class="amount">' + this_val + (json.full ? '' : '*') + '</p>';
        fcvalh += '<p class="subamount text-sm">' + json.fcvst[2][0] + '</p>';
        fcvalh += '</div>';
      }

      for (var xx = 4; xx <= 16; xx += 4) {
        if (typeof json.fcvst[xx] !== 'undefined' && json.fcvst[xx] != null) {
          this_val = '';
          dec_val = val_format(json.fcvst[xx][2], 2);
          this_val =
            dec_val.split('.')[0] + '<span class="dec">.' + dec_val.split('.')[1] + '</span>';
          fcvalh +=
            '<div class="one_fifth print_one_fifth tablet_one_third mobile_one_half valuation_amount market_colour">';
          fcvalh +=
            '<p class="valuation_undertitle">+' +
            xx / 4 +
            ' Year' +
            (xx / 4 > 1 ? 's' : '') +
            '</p>';
          fcvalh += '<p class="amount">' + this_val + (json.full ? '' : '*') + '</p>';
          fcvalh += '<p class="subamount text-sm">' + json.fcvst[xx][0] + '</p>';
          fcvalh += '</div>';
        }
      }
    }

    if (!json.full) {
      fcvalh +=
        '<div class="full_width"><p class="bbbump vv-m-0">* Excludes vessels which you cannot value or do not have a Forecast available.</p></div>';
    }

    valuation_dates.html(fcvalh);
  } else {
    valuation_dates.html(
      '<div class="full_width print_one_fifth tablet_full_width valuation_amount market_colour"><p>****</p><p class="valuation_undertitle">No Forecast Available</p></div>',
    );
  }

  // Spark Lines
  if (nb_sparkline.length > 0 && typeof json.tyspkln !== 'undefined') {
    nb_sparkline.highcharts(json.nbspkln);
  }

  if (type_sparkline.length > 0 && typeof json.nbspkln !== 'undefined') {
    type_sparkline.highcharts(json.tyspkln);
  }

  if (typeof json.fcpbd !== 'undefined' && fc_published.length > 0) {
    fc_published.html(json.fcpbd);
  }

  // Commentary
  if (typeof fc_commentary !== 'undefined' && fc_commentary.length > 0) {
    var comments = '';
    if (typeof json.vmcom !== 'undefined' && json.vmcom.length > 0) {
      comments +=
        '<a class="fr ml-3 mb-3" style="margin-top:-5px; margin-right: -10px;" href="http://www.viamar.no" target="_blank" rel="nofollow" alt="ViaMar"><img loading="lazy" src="' +
        img_url +
        'viamar-logo.png" style="height:60px; margin-left: auto;"/></a>';
      comments += json.vmcom;
    }

    if (typeof json.vvcom !== 'undefined' && json.vvcom.length > 0) {
      comments +=
        '<h3><img src="' +
        img_url +
        'logo_inverted.png" style="width: 150px;" alt="VesselsValue"/></h3>';
      comments += json.vvcom;
    }

    comments +=
      '<a id="viaMarRequestInfo" class="button vv-mt-10 vv-mb-20 ib">Request more info</a>';

    if (comments.length > 0) {
      fc_commentary.html('');
      fc_commentary.html(comments.replace(/<p>/g, '<p class="py-2">'));
    }

    fc_commentary.on('click', '#viaMarRequestInfo', function (e) {
      e.preventDefault();
      bb_get(site_url + 'ajax/get_viamar_info_dialog/', {}, function (html) {
        dialog_show(this, 600, html, 'Request more info');
      });
    });
  }

  // Short Term
  var table_html = '';
  var not_available =
    '<tr class=""><td colspan="3">Not available, likely due to the vessel reaching end of its economic life</td></tr>';

  valuation_table.html(table_html);
  table_html += '<tr><td colspan="3"><b>Short Term Forecast</b></td></tr>';
  if (typeof json.fcvst !== 'undefined') {
    var ii = 0;
    for (x in json.fcvst) {
      // Output Yearly + 6 month forecast.
      if (json.fcvst.hasOwnProperty(x) && (x % 4 === 0 || ++ii === 2)) {
        table_html += '<tr>';
        table_html += '<td class="ar">' + json.fcvst[x][0] + '</td>';
        table_html += '<td class="ar">' + json.fcvst[x][1] + '</td>';
        table_html += '<td class="ar">' + json.fcvst[x][2] + '</td>';
        table_html += '</tr>';
      }
    }
  } else {
    table_html += not_available;
  }

  if (json.ships <= 1) {
    // Mid-Term / Transition
    table_html += '<tr><td colspan="3"><b>Transition</b></td></tr>';
    if (typeof json.fcvmt !== 'undefined') {
      for (x in json.fcvmt) {
        if (json.fcvmt.hasOwnProperty(x)) {
          table_html += '<tr class="odd">';
          table_html += '<td class="ar">' + json.fcvmt[x][0] + '</td>';
          table_html += '<td class="ar">' + json.fcvmt[x][1] + '</td>';
          table_html += '<td class="ar">' + json.fcvmt[x][2] + '</td>';
          table_html += '</tr>';
        }
      }
    } else {
      table_html += not_available;
    }

    // Long Term
    table_html += '<tr><td colspan="3"><b>Long Term Forecast</b></td></tr>';
    if (typeof json.fcvlt !== 'undefined') {
      for (x in json.fcvlt) {
        if (json.fcvlt.hasOwnProperty(x)) {
          table_html += '<tr>';
          table_html += '<td class="ar">' + json.fcvlt[x][0] + '</td>';
          table_html += '<td class="ar">' + json.fcvlt[x][1] + '</td>';
          table_html += '<td class="ar">' + json.fcvlt[x][2] + '</td>';
          table_html += '</tr>';
        }
      }
    } else {
      table_html += not_available;
    }
  }
  valuation_table.append(table_html);

  return true;
}

var marketTickPositioner = function () {
  var positions = [];

  var max = this.dataMax;
  var min = this.dataMin;
  if (this.dataMax !== null && this.dataMin !== null) {
    var crossesZero = Math.sign(max) !== Math.sign(min) && min !== 0;

    if (crossesZero) {
      var max_mag = Math.pow(10, Math.floor(Math.log10(max)));
      max = Math.ceil(max / max_mag) * max_mag;

      var min_mag = Math.pow(10, Math.floor(Math.log10(Math.abs(min))));
      min = Math.floor(min / min_mag) * min_mag;

      var q1n = (max * 0.25 * -1) / 2;
      positions = [q1n < min ? q1n : min, 0, max * 0.25, max * 0.5, max * 0.75, max];
    } else {
      var mag = Math.pow(10, Math.floor(Math.log10(Math.max(Math.abs(min), Math.abs(max)))));
      max = Math.ceil(max / mag) * mag;
      min = Math.floor(min / mag) * mag;

      positions = [
        min,
        max * 0.25 + min * 0.75,
        max * 0.5 + min * 0.5,
        max * 0.75 + min * 0.25,
        max,
      ];
    }
  }

  return positions;
};

var lollipopTickPositioner = function (min, max) {
  var series = this.series.find(function (data) {
    return data.name === 'Market Value';
  });

  if (series && series.processedYData) {
    var yMin = Math.min.apply(Math, series.processedYData);
    var yMax = Math.max.apply(Math, series.processedYData);
    var lollipopHeight = (max - min) * 0.1;
    var setMin = yMin - lollipopHeight;
    var setMax = yMax + lollipopHeight;

    return this.getLinearTickPositions(
      this.tickInterval,
      setMin <= min ? setMin : min,
      setMax >= max ? setMax : max,
    );
  }
};

// WOW THIS IS SOME LEGACY STUFF BELOW!!!

function pagination(ttl, start, type) {
  $('#pagination').empty();
  var pageButtonClass =
    'rounded border border-grey-200 hover:border-red-500 inline-block text-center px-2 ml-1 py-1 md:flex items-center flex';
  var pg_btn_cnt = ttl / search_limit;

  if (pg_btn_cnt > 1) {
    if (start == 0) {
      $('#pagination').append(
        '<a href="#" onclick="getSuggest(0,\'' +
          type +
          "','" +
          searchClean($('#search_input').val()) +
          "', false, false, '" +
          search_order +
          '\'); return false;" class="' +
          pageButtonClass +
          'faded">&lt;&lt;</a>',
      );
    } else {
      $('#pagination').append(
        '<a href="#" onclick="getSuggest(0,\'' +
          type +
          "','" +
          searchClean($('#search_input').val()) +
          "', false, false, '" +
          search_order +
          '\'); return false;" class="' +
          pageButtonClass +
          '">&lt;&lt;</a>',
      );
    }

    for (var i = 0; i <= Math.ceil(pg_btn_cnt) - 1; i++) {
      var class1 = '';

      if (start == i * search_limit) {
        class1 = 'page_active';
      } else if (i == 0 && typeof start == 'undefined') {
        class1 = 'page_active';
      }

      pos = i + 1;

      $('#pagination').append(
        '<a href="#" onclick="getSuggest(' +
          i * search_limit +
          ",'" +
          type +
          "','" +
          searchClean($('#search_input').val()) +
          "', false, false, '" +
          search_order +
          '\'); return false;" class="' +
          class1 +
          ' ' +
          pageButtonClass +
          '">' +
          pos +
          '</a>',
      );
    }
    last = Math.ceil(pg_btn_cnt) - 1;

    if (start == last * search_limit) {
      $('#pagination').append(
        '<a href="#" onclick="getSuggest(' +
          last * search_limit +
          ",'" +
          type +
          "','" +
          searchClean($('#search_input').val()) +
          "', false, false, '" +
          search_order +
          '\'); return false;" class="' +
          pageButtonClass +
          ' faded">&gt;&gt;</a>',
      );
    } else {
      $('#pagination').append(
        '<a href="#" onclick="getSuggest(' +
          last * search_limit +
          ",'" +
          type +
          "','" +
          searchClean($('#search_input').val()) +
          "', false, false, '" +
          search_order +
          '\'); return false;" class="' +
          pageButtonClass +
          '">&gt;&gt;</a>',
      );
    }
  }
}

function getSuggest(start, type, last_search_term, drop_down, inc_port, order) {
  var search_term = searchClean($('#search_input').val(), true);
  var search_field = $('input:checked').val();
  if (search_term.length >= 2 && search_term == last_search_term) {
    $('#search_input').css({
      background: 'url(' + img_url + 'ajax-loader.gif) no-repeat 98% center transparent',
      'background-color': '#FFE3E5',
    });
    var params = {
      term: search_term,
      field: search_field,
    };
    if (typeof start != 'undefined') params['start'] = start;
    if (typeof inc_port != 'undefined' && inc_port !== false) params['inc_ports'] = '';
    if (type == 'portfolio_ships') params['p_id'] = portfolio_id;
    if (typeof order != 'undefined') params['order'] = order;
    bb_get(site_url + 'ajax/search/', params, function (xml) {
      var notification = $(xml).find('notification').text();
      if (notification > '')
        $('#search_results').append('<tr><td colspan="100">' + notification + '</td></tr>');
      else if (!drop_down) main_search_display(xml, start, type);
      else drop_down_search(xml);
      $('#search_input').css('background', '#FFFFFF');
    });
  }
}

function restrictMapToSingleWorld(element) {
  var newWidth = 0;
  var maxWidth = Math.pow(2, maps[element.attr('id')].getZoom() + 8);

  if (element.width() >= maxWidth || maxWidth <= element.parent().width()) {
    newWidth = maxWidth + 'px';
  } else {
    newWidth = '100%';
  }

  if (element.css('width') != newWidth) {
    element.css({
      width: newWidth,
      minWidth: newWidth,
      maxWidth: '100%',
    });
  }

  google.maps.event.trigger(maps[element.attr('id')], 'resize');
}

function do_sort(order, desc) {
  if (search_order == order) search_order += ' DESC';
  else if (search_order == order + ' DESC') search_order = order;
  else if (desc === true) search_order = order + ' DESC';
  else search_order = order;
  do_search();
}

function do_search(type) {
  if (typeof type == 'undefined') type = 'search';
  last_search_term = $('#search_input').val();
  setTimeout(
    'getSuggest(0, "' +
      type +
      '", "' +
      last_search_term +
      '", false, false, "' +
      search_order +
      '");',
    500,
  );
}

function do_drop_search(inc_ports) {
  last_search_term = $('#search_input').val();
  if (typeof inc_ports == 'undefined')
    setTimeout(
      'getSuggest(0, "search", "' +
        last_search_term +
        '", true, "inc_ports", "' +
        search_order +
        '");',
      500,
    );
  else
    setTimeout(
      'getSuggest(0, "search", "' + last_search_term + '", true, false, "' + search_order + '");',
      500,
    );
}

function drop_down_search(xml, div, animate) {
  var inc_port = 0;
  if (typeof div == 'undefined') div = '#drop_search_result';
  if (typeof animate == 'undefined' || animate !== false) animate = true;
  $(div).empty();
  var data = $(xml).find('data').toArray();
  for (var key in data) {
    var value = data[key];
    var str = '';
    switch ($(value).find('s_type').text()) {
      case 'v':
        var ship_name = $(value).find('ship_name').text();
        var ship_id = $(value).find('ship_id').text();
        var ship_imo = $(value).find('ship_imo').text();
        var ship_size = $(value).find('ship_size').text();
        var size_type = $(value).find('size_type').text();
        var yob = $(value).find('ship_build_year').text();
        var ship_type = $(value).find('ship_type').text();
        var ship_builder_name = $(value).find('ship_builder_name').text();
        var ship_builder_country = $(value).find('ship_builder_country').text();

        if (window.search_type != 'chart')
          var item = $(
            '<div class="result_item" onclick="add_alert_to(\'' +
              ship_name +
              "', '" +
              ship_id +
              "', 'v');\" />",
          );
        else
          var item = $(
            '<div class="result_item" onclick="location.href = \'' +
              site_url +
              'valuation/' +
              ship_imo +
              '/' +
              str_replace(' ', '_', ship_name) +
              '/?ship_id=' +
              ship_id +
              '\'" />',
          );

        $(item).append(
          '<div class="red fl" style="overflow: hidden; height: 20px; float: left;">' +
            ship_name +
            '</div>',
        );
        $(item).append('<div class="fr" />');
        $(item).find('.fr').append(ship_type);
        $(item)
          .find('.fr')
          .append('<span style="padding:0 3px;color:#666666">' + size_type + '</span>');
        $(item).find('.fr').append(ship_size);
        $(item).find('.fr').append('<span style="padding: 0 3px; color: #666666;">BLT</span>');
        $(item).find('.fr').append(yob);

        $(div).append(item);
        $(div).append('<div class="clear" />');
        break;

      case 'p':
        inc_port++;
        var portfolio_id = $(value).find('portfolio_id').text();
        var portfolio_name = $(value).find('portfolio_name').text();
        var vessel_count = $(value).find('vessel_count').text();

        if (inc_port == 1) $(div).append('<div class="result_item pf">Portfolios</div>');

        var item = $(
          '<div class="result_item" onclick="add_alert_to(\'' +
            portfolio_name +
            "', '" +
            portfolio_id +
            "', 'p');\" />",
        );
        $(item).append('<div class="red fl pf">' + portfolio_name + '</div>');
        $(item).append(
          '<div style="color: #4C4C4C; float: right;">No. Vessels ' +
            parseInt(vessel_count) +
            '</div>',
        );
        $(item).append('<div class="clear" />');

        $(div).append(item);
        break;
    }
  }

  if (animate) $(div).slideDown(200);

  if ($(div).html() === '')
    $(div).append(
      '<div class="result_item" style="text-align:left; color:#A51E22;"><b>No results Found<b></div>',
    );
}

function initialiseAnimation() {
  var $animationElements = $('.with_transitions');
  var $window = $(window);

  var $is_visible = function is_visible() {
    var window_height = $window.height();
    var window_top = $window.scrollTop();
    var window_bottom = window_top + window_height;

    $.each($animationElements, function () {
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top = $element.offset().top;
      var element_bottom = element_top + element_height;

      if (element_bottom >= window_top && element_top <= window_bottom) {
        $element.addClass('trigger_transition');
      } else {
        // $element.removeClass('trigger_transition');
      }
    });
  };

  $window.on('scroll resize', $is_visible);
  $window.trigger('scroll');
}

function main_search_display(xml, start, type) {
  var odd = 1;
  $('#search_results').empty();
  pagination($(xml).find('base').attr('ttl_cnt'), start, type);
  if (type == 'portfolio_ships') {
    $('#add_check_all').empty();
    $('#add_check_all').append('<option value="none" selected="selected">Select All</option>');
    $('#add_check_all').append('<option value="line" disabled="disabled">---------</option>');
    $('#add_check_all').append('<option value="page">Page</option>');
    $('#add_check_all').append('<option value="all">All Results</option>');
  }
  $(xml)
    .find('data')
    .each(function (key, value) {
      var ship_imo = $(value).find('ship_imo').text();
      var ship_id = $(value).find('ship_id').text();
      var ship_name = $(value).find('ship_name').text();
      var operator_id = $(value).find('operator_id').text();
      var operator_name = $(value).find('operator_name').text();
      var ship_type = $(value).find('ship_type').text();
      var ship_builder_name = $(value).find('ship_builder_name').text();
      var builder_country_name = $(value).find('builder_country_name').text();
      var builder_flag = $(value).find('builder_flag').text();
      var operator_country_name = $(value).find('operator_country_name').text();
      var operator_flag = $(value).find('operator_flag').text();
      var ship_build_year = $(value).find('ship_build_year').text();
      var ship_size = $(value).find('ship_size').text();
      var size_type = $(value).find('size_type').text();
      var ship_value = $(value).find('ship_value').text();
      var in_portfolio = $(value).find('portfolio_id').text();
      var css_class = '';
      var string = '';
      if (odd % 2 == 0) css_class = 'odd';
      odd++;
      string += '<tr class="in_portfolio ' + css_class + '" id="' + ship_id + '">';
      if (type == 'portfolio' || type == 'portfolio_ships') {
        if (in_portfolio == portfolio_id) {
          string += '<td';
          if (search_order.indexOf('ship_name') == 0) string += ' class="sorted"';
          string +=
            ' id="add_' +
            ship_id +
            '" title="' +
            ship_name +
            '"><div class="fh">' +
            ship_name +
            '</div></td>';
        } else {
          string += '<td><input type="checkbox" value="" id="' + ship_id + '" name="" /></td>';
          string += '<td class="add';
          if (search_order.indexOf('ship_name') == 0) string += ' sorted';
          string +=
            '" id="add_' +
            ship_id +
            '"><div class="fh"><a href="#" onclick="add_vessel_to_portfolio(' +
            "'" +
            ship_id +
            "'" +
            ');" title="' +
            ship_name +
            '">' +
            ship_name +
            '</a></div></td>';
        }
      } else {
        string += '<td';
        if (search_order.indexOf('ship_name') == 0) string += ' class="sorted"';
        string +=
          '><div class="fh"><a class="red" href="' +
          site_url +
          'valuation/' +
          ship_imo +
          '/' +
          str_replace(' ', '_', ship_name) +
          '/?ship_id=' +
          ship_id +
          '" title="' +
          ship_name +
          '">' +
          ship_name +
          '</a></div></td>';
      }
      string += '<td class="al';
      if (search_order.indexOf('ship_type') == 0) string += ' sorted';
      string += '" title="' + ship_type + '"><div class="fh">' + ship_type + '</div></td>';
      string += '<td class="ar';
      if (search_order.indexOf('f.ship_size') == 0) string += ' sorted';
      string += '">' + ship_size + ' ' + size_type + '</td>';
      string += '<td class="ac';
      if (search_order.indexOf('f.ship_build_year') == 0) string += ' sorted';
      string += '">' + ship_build_year + '</td>';
      string += '<td class="ac';
      if (search_order.indexOf('bc.country_name') == 0) string += ' sorted';
      string += '">' + builder_flag + '</td>';
      string += '<td class="al';
      if (search_order.indexOf('y.ship_builder_name') == 0) string += ' sorted';
      string +=
        '" title="' +
        ship_builder_name +
        ', ' +
        builder_country_name +
        '"><div class="fh">' +
        ship_builder_name +
        '</div></td>';
      string += '<td class="ac';
      if (search_order.indexOf('oc.country_name') == 0) string += ' sorted';
      string += '">' + operator_flag + '</td>';
      string += '<td class="al';
      if (search_order.indexOf('o.operator_name') == 0) string += ' sorted';
      string +=
        '" title="' +
        operator_name +
        ', ' +
        operator_country_name +
        '"><div class="fh"><a href="' +
        site_url +
        'company/' +
        operator_id +
        '/' +
        str_replace(' ', '_', operator_name) +
        '/" title="' +
        operator_name +
        ', ' +
        operator_country_name +
        '">' +
        operator_name +
        '</a></div></td>';
      string += '</tr>';
      $('#search_results').append(string);
    });
  if ($('#search_results').html() === '')
    $('#search_results').append(
      '<tr><td colspan="8" style="text-align:left; color:#A51E22;"><b>No results Found<b></td></tr>',
    );
  $('#search_head').css('visibility', 'visible');
  $('#add_portfolio').css('display', 'block');
}

$('a.dummy_link').click(function (e) {
  e.preventDefault();

  return false;
});

function col_count(table_element) {
  table_element = $(table_element);

  var colCount = 0;

  $(table_element)
    .find('tr:first > td')
    .each(function () {
      if ($(this).attr('colspan')) {
        colCount += +$(this).attr('colspan');
      } else {
        colCount++;
      }
    });

  return colCount;
}

function clone(object) {
  return $.extend({}, object);
}

if (typeof Math.sign === 'undefined') {
  Math.sign = function (num) {
    num = parseFloat(num);

    if (num > 0) {
      return 1;
    }

    if (num < 0) {
      return -1;
    }

    return 0;
  };
}

function isOptimisedPage() {
  return new URLSearchParams(window.location.search).get('optimise') === 'true';
}

function isTabOpen(tab) {
  return window.location.href.indexOf(tab) !== -1;
}

// DON'T PUT ANYTHING NEW HERE - THIS BOTTOM BIT'S FOR LEGACY STUFF
